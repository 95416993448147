import React from "react"
import Modal from 'react-bootstrap/Modal'
import modalService, {ModalConfig} from "src/components/modal/global/modal.service"
import Icon from "src/components/shared/components/material-icon"

export const ModalComponent = (config: ModalConfig & {show: boolean, removable?: boolean, index?: string}) => {
  const removable = config.removable !== undefined ? config.removable : true
  const handleModalToggle = () => {
    modalService.closeModal(config.index)
    if (config.onHide !== undefined) config.onHide()
  }

  const component = React.cloneElement(config.component, {id: config.index})

  return (
    <Modal
      show={config.show}
      {...config.props}
      onHide={config.props?.backdrop ? () => undefined : handleModalToggle}
      centered={true}
    >
      <Modal.Body>
        {removable && <div className="modal-remove" onClick={handleModalToggle}>
          <Icon icon="close" />
        </div>}

        {component}

      </Modal.Body>
    </Modal >
  )
}
