import Table from "src/components/shared/antd-custom/table"
import React, {useEffect, useState} from 'react'
import {DndContext} from '@dnd-kit/core'
import {restrictToVerticalAxis} from '@dnd-kit/modifiers'
import {arrayMove, SortableContext, useSortable, verticalListSortingStrategy} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import type {DragEndEvent} from '@dnd-kit/core'
import type {ColumnsType} from 'antd/es/table'
import websiteService from "src/services/website.service"
import {ProductOrder, ProductTypeName} from "src/models/manager/website"
import {getPipe} from "src/utils/constant"
import {useStore} from "src/store/storeHooks"
import {CategoryModel} from "src/models/manager/constants"
import Icon from "src/components/shared/components/material-icon"

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string
}

const Row = ({children, ...props}: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  })

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && {...transform, scaleY: 1}),
    transition,
    ...(isDragging ? {position: 'relative', zIndex: 99} : {}),
  }

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        const element: React.ReactElement = child as React.ReactElement

        if (element.key === 'sort') {
          return React.cloneElement(element, {
            children: <Icon icon="menu" className="btn btn-icon" ref={setActivatorNodeRef} {...listeners} />
          })
        }

        return child
      })}
    </tr>
  )
}

const WebsiteProductOrder = () => {
  const {constants} = useStore(({app}) => app)
  const [list, setList] = useState<ProductOrder[]>([])

  const columns: ColumnsType<ProductOrder> = [
    {
      width: '1%',
      key: 'sort',
    },
    {
      width: '60%',
      title: 'Название',
      key: 'name',
      dataIndex: 'name',
      render: name => name,
    },
    {
      title: 'Категория',
      key: 'category',
      dataIndex: 'category',
      render: category => getPipe(category, constants.INVENTORY_CATEGORIES, (val: CategoryModel) => val.name),
    },
    {
      title: 'Тип',
      key: 'type',
      dataIndex: 'type',
      render: type => ProductTypeName[type],
    }
  ]

  const onDragEnd = ({active, over}: DragEndEvent) => {
    if (active.id !== over?.id) {
      setList((previous) => {
        const activeIndex = previous.findIndex((i) => i.id === active.id)
        const overIndex = previous.findIndex((i) => i.id === over?.id)
        websiteService.patchProductOrder(active.id as number, {order: overIndex + 1})
        return arrayMove(previous, activeIndex, overIndex)
      })
    }
  }

  useEffect(() => {
    websiteService.listProductOrders().then(setList)
    return () => setList([])
  }, [])

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext
        items={list}
        strategy={verticalListSortingStrategy}
      >
        <div className="flex gap-3 items-center mb-3">
          <div className="text-xl font-semibold">Ордерация продуктов</div>
        </div>
        <Table
          components={{body: {row: Row}}}
          rowKey={obj => obj.id}
          columns={columns}
          dataSource={list}
        />
      </SortableContext>
    </DndContext>
  )
}

export default WebsiteProductOrder