import {useContext, useEffect} from 'react'
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {Outlet} from 'react-router'
import {ConfigContext} from "src"
import HeaderContent from "src/components/header/header-content"
import {SidebarWrapper} from "src/components/shared/components/side-wrapper"
import {SettingConfig} from 'src/models/setting-config'

export default function SettingsComponent() {
  const form = useForm<SettingConfig>({defaultValues: {rent_product_types: ['group', 'sale', 'inventory']}})
  const {settings} = useContext(ConfigContext)
  const {t} = useTranslation()

  useEffect(() => {
    form.reset(settings)
    return () => { }
  }, [form, settings])

  return (
    <FormProvider {...form}>
      <HeaderContent>
        <div className="text-2xl font-semibold header-content">{t('settings.header')}</div>
      </HeaderContent>

      <SidebarWrapper options={[
        {
          menus: [
            {link: '/settings/main', label: t('settings.sections.main')},
            {link: '/settings/rent', label: t('settings.sections.rent')},
            {link: '/settings/order-status', label: t('settings.sections.order-status')},
            {link: '/settings/inventory-state', label: t('settings.sections.inventory-state')},
            {link: '/settings/bonus', label: "Бонусы"},
            {link: '/settings/managers', label: t('settings.sections.managers')},
            {link: '/settings/custom-fields', label: t('settings.sections.custom-fields')},
          ]
        }
      ]}>
        <Outlet />
      </SidebarWrapper>
    </FormProvider >
  )
}
