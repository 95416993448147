import {lazy} from 'react'
import {Navigate, RouteObject} from 'react-router-dom'
import CustomSuspense from "src/components/custom-suspense"
import ReportClientBonusWrapperComponent from "src/pages/reports/bonuses"
import ReportPayback from "src/pages/reports/inventory"
import InventoryPaybackWrapper from "src/pages/reports/inventory/payback"
import ReportComponent from "src/pages/reports/reports"
import ReportSellsLayout from "src/pages/reports/sales"
import ReportManagerLayout from "src/pages/reports/managers"
import UserPermissionComponent from "src/pages/accounts"
import ProfileEditComponent from "src/pages/profile/edit"
import ProfileComponent from "src/pages/profile"
import ProfileActivityComponent from "src/pages/profile/activity"
import {TenantSubscriptionModal} from "src/components/modal/components/subscription"

const ConfigListComponent = lazy(() => import('src/pages/config/config'))
const ConfigClientTickComponent = lazy(() => import('src/pages/config/config-client-ticks'))
const ConfigDiscountComponent = lazy(() => import('src/pages/config/config-discounts'))
const ConfigInventoryCategoryComponent = lazy(() => import('src/pages/config/config-category'))
const ConfigPaymentTypeComponent = lazy(() => import('src/pages/config/config-payment-type'))
const ConfigRentalDurationComponent = lazy(() => import('src/pages/config/config-rental-duriation'))
const ConfigRentalPointsComponent = lazy(() => import('src/pages/config/config-rental-points'))
const ConfigServicesComponent = lazy(() => import('src/pages/service/list'))
const TarifListComponent = lazy(() => import('src/pages/config/config-tarifs'))
const DocumentList = lazy(() => import('src/pages/config/documents/list'))
const DocumentTemplate = lazy(() => import('src/pages/config/documents/template'))
const UserPermissionGroupEdit = lazy(() => import('src/pages/accounts/group/group-edit'))
const UserStaffEdit = lazy(() => import('src/pages/accounts/user/user-edit'))
const InventorizationComponent = lazy(() => import('src/pages/inventorization/inventorization'))
const InventorizationHistoryComponent = lazy(() => import('src/pages/inventorization/inventorization-history'))
const InventorizationTaskListComponent = lazy(() => import('src/pages/inventorization/inventorization-tasklist'))
const ReportClientsComponent = lazy(() => import('src/pages/reports/client'))
const ReportDashboardComponent = lazy(() => import('src/pages/reports/dashboard'))
const InventoryPaybackComponent = lazy(() => import('src/pages/reports/inventory/payback/payback-inventories'))
const ReportSalesComponent = lazy(() => import('src/pages/reports/sales/finance'))
const ReportPenaltiesList = lazy(() => import('src/pages/reports/sales/penalties'))
const ReportUserSubleaseEarnings = lazy(() => import('src/pages/reports/sublease'))
const ReportUserServiceEarnings = lazy(() => import('src/pages/reports/services'))

const ReportUserSalaryComponent = lazy(() => import('src/pages/reports/managers/salary'))

const InventoryGroupPaybackComponent = lazy(() => import('src/pages/reports/inventory/payback/payback-groups'))
const InventoryEfficencyComponent = lazy(() => import('src/pages/reports/inventory/efficency'))

const ReportClientBonusHistoryComponent = lazy(() => import('src/pages/reports/bonuses/history'))
const ReportClientBonusBalanceComponent = lazy(() => import('src/pages/reports/bonuses/balance'))

const UserList = lazy(() => import('src/pages/accounts/user/list'))
const UserPermissionGroupList = lazy(() => import('src/pages/accounts/group/list'))
const ConfigClientAttactionMethodComponent = lazy(() => import('src/pages/config/config-client-attraction-methods'))
const ReportWorkshiftList = lazy(() => import('src/pages/reports/managers/workshift'))


export const configRoutes: RouteObject[] = [
  {path: 'services', element: <CustomSuspense><ConfigServicesComponent /></CustomSuspense>},
  {path: 'config/documents/add', element: <CustomSuspense><DocumentTemplate /></CustomSuspense>},
  {path: 'config/documents/:id', element: <CustomSuspense><DocumentTemplate /></CustomSuspense>},
  {
    path: 'config',
    element: <CustomSuspense><ConfigListComponent /></CustomSuspense>,
    children: [
      {path: '', element: <Navigate to="rental-points" replace={true} />},
      {path: 'rental-points', element: <CustomSuspense><ConfigRentalPointsComponent /></CustomSuspense>},
      {path: 'lease-terms', element: <CustomSuspense><ConfigRentalDurationComponent /></CustomSuspense>},
      {path: 'discounts', element: <CustomSuspense><ConfigDiscountComponent /></CustomSuspense>},
      {path: 'client-ticks', element: <CustomSuspense><ConfigClientTickComponent /></CustomSuspense>},
      {path: 'inventory-category', element: <CustomSuspense><ConfigInventoryCategoryComponent /></CustomSuspense>},
      {path: 'attraction-methods', element: <CustomSuspense><ConfigClientAttactionMethodComponent /></CustomSuspense>},
      {path: 'services', element: <CustomSuspense><ConfigServicesComponent /></CustomSuspense>},
      {path: 'payment-types', element: <CustomSuspense><ConfigPaymentTypeComponent /></CustomSuspense>},
      {path: 'tarifs', element: <CustomSuspense><TarifListComponent /></CustomSuspense>},
      {path: 'documents', element: <CustomSuspense><DocumentList /></CustomSuspense>},
      {path: '*', element: <Navigate to="rental-points" replace={true} />},
    ],
  },
  {
    path: 'inventorization',
    element: <CustomSuspense><InventorizationComponent /></CustomSuspense>,
    children: [
      {path: '', element: <Navigate to="tasklist" relative="route" replace={true} />},
      {path: 'tasklist', element: <CustomSuspense><InventorizationTaskListComponent /></CustomSuspense>},
      {path: 'history', element: <CustomSuspense><InventorizationHistoryComponent params={{}} /></CustomSuspense>},
    ],
  },
  {
    path: 'reports',
    element: <ReportComponent />,
    children: [
      {path: '', element: <Navigate to="sales" relative="route" replace={false} />},
      {path: 'overall', element: <CustomSuspense><ReportDashboardComponent /></CustomSuspense>},
      {
        path: 'inventory',
        element: <ReportPayback />,
        children: [
          {path: '', element: <Navigate to="efficency" replace={false} />},
          {path: 'efficency', element: <CustomSuspense><InventoryEfficencyComponent /></CustomSuspense>},
          {
            path: 'payback',
            element: <InventoryPaybackWrapper />,
            children: [
              {path: '', element: <Navigate to="group" replace={false} />},
              {path: 'group', element: <CustomSuspense><InventoryGroupPaybackComponent /></CustomSuspense>},
              {path: 'inventory', element: <CustomSuspense><InventoryPaybackComponent /></CustomSuspense>},
            ]
          },
        ],
      },
      {
        path: 'sales',
        element: <ReportSellsLayout />,
        children: [
          {path: '', element: <Navigate to="main" replace={false} />},
          {path: 'main', element: <CustomSuspense><ReportSalesComponent /></CustomSuspense>},
          {path: 'penalties', element: <CustomSuspense><ReportPenaltiesList /></CustomSuspense>}
        ],
      },
      {
        path: 'managers',
        element: <ReportManagerLayout />,
        children: [
          {path: '', element: <Navigate to="salary" replace={false} />},
          {path: 'salary', element: <CustomSuspense><ReportUserSalaryComponent /></CustomSuspense>},
          {path: 'workshifts', element: <CustomSuspense><ReportWorkshiftList /></CustomSuspense>},
        ]
      },
      {
        path: 'bonuses',
        element: <ReportClientBonusWrapperComponent />,
        children: [
          {path: '', element: <Navigate to="balance" replace={false} />},
          {path: 'balance', element: <CustomSuspense><ReportClientBonusBalanceComponent /></CustomSuspense>},
          {path: 'history', element: <CustomSuspense><ReportClientBonusHistoryComponent /></CustomSuspense>},
        ]
      },
      {path: 'services', element: <CustomSuspense><ReportUserServiceEarnings /></CustomSuspense>},
      {path: 'sublease', element: <CustomSuspense><ReportUserSubleaseEarnings /></CustomSuspense>},
      {path: 'clients', element: <CustomSuspense><ReportClientsComponent /></CustomSuspense>},
      {path: '*', element: <Navigate to="sales" replace={false} />},
    ],
  },
  {
    path: 'users',
    element: <UserPermissionComponent />,
    children: [
      {path: '', element: <Navigate to="main" replace={true} />},
      {path: 'main', element: <CustomSuspense><UserList params={{is_staff: true}} addLink="/users/add" /></CustomSuspense>},
      {path: 'sublease', element: <CustomSuspense><UserList params={{is_sublease: true}} addLink="/users/sublease/add" /></CustomSuspense>},
      {path: 'roles', element: <CustomSuspense><UserPermissionGroupList /></CustomSuspense>}
    ]
  },
  {path: 'users/add', element: <CustomSuspense><UserStaffEdit params={{is_staff: true}} /></CustomSuspense>},
  {path: 'users/:id', element: <CustomSuspense><UserStaffEdit params={{is_staff: true}} /></CustomSuspense>},
  {path: 'users/sublease/add', element: <CustomSuspense><UserStaffEdit params={{is_sublease: true}} /></CustomSuspense>},
  {path: 'users/sublease/:id', element: <CustomSuspense><UserStaffEdit params={{is_sublease: true}} /></CustomSuspense>},
  {path: 'users/roles/:id', element: <CustomSuspense><UserPermissionGroupEdit /></CustomSuspense>},
  {path: 'users/roles/add', element: <CustomSuspense><UserPermissionGroupEdit /></CustomSuspense>},
  {
    path: 'profile',
    element: <ProfileComponent />,
    children: [
      {path: '', element: <Navigate to="about" replace={true} />},
      {path: 'about', element: <ProfileEditComponent />},
      {path: 'activity', element: <ProfileActivityComponent />},
      {path: 'subscription', element: <TenantSubscriptionModal />}
    ]
  },
]
