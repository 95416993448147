import {CSSProperties} from 'react'
import './style.scss'

interface SegmentOption {
  label: string
  value: string
}

interface SegmentConfig {
  options: SegmentOption[]
  onChange: (string) => void
  value: string
  segmentStyle?: CSSProperties
  optionStyle?: (active: boolean) => CSSProperties
}

const Segmented: React.FC<SegmentConfig> = ({options, onChange, value, segmentStyle, optionStyle}) => {
  return (
    <div className="segment" style={segmentStyle}>
      {options.map(option => (
        <div
          key={option.value}
          onClick={() => onChange(option.value)}
          className={`segment-option cursor-pointer ${value === option.value ? 'active' : ''}`}
          style={optionStyle && optionStyle(value === option.value)}
        >
          {option.label}
        </div>
      ))}
    </div>
  )
}

export default Segmented
