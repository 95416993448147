
import {TableProps} from 'antd/es/table'
import {Table} from "antd"
import React from "react"
import {SorterResult} from "antd/es/table/interface"
import {useFormContext} from "react-hook-form"

export interface _TableProps extends Omit<TableProps<any>, 'scroll'> { }

const _Table = React.forwardRef<any, _TableProps>((props, ref) => {
    const max = props.columns.reduce((p, c) => (+c.width || 0) + p, 0) || 720
    const form = useFormContext()
    const {reset, watch} = form || {reset: (...args) => { }, watch: (...args) => { }}
    const values = watch()

    const onChange = (_pagination: any, _filters: any, sorter: SorterResult<any>) => {
        const sort = sorter.order === 'ascend' ? '' : '-'
        reset({...values, ordering: sorter.order ? `${sort}${sorter.field}` : null})
    }

    return <Table
        bordered={false}
        pagination={false}
        {...props}
        scroll={{x: `max(${max}px, 100%)`}}
        onChange={form ? onChange : props.onChange}
        ref={ref}
    />
})

export default _Table