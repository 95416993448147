import {useEffect} from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from "react-i18next"
import {AsyncRentalPoint} from "src/abstract/async-rental-point"
import {SharedCategorySelect} from "src/components/shared/components/select/category"
import {FormControl} from "src/components/shared/inputs/form-control"
import {useDebounce} from "src/hooks/useDebounce"
import {ListParams} from "src/models/common"

export interface ProductSetFilterModel extends ListParams {
    rental_point: number
    archived: boolean
    search: string
}

export default function ProductSetFilterComponent() {
    const {t} = useTranslation()
    const form = useFormContext<any>()
    const {watch, reset} = form
    const values = watch()

    const searchDebounce = useDebounce(watch('search'), 500)
    useEffect(() => reset({...values, search: searchDebounce, page: 1}), [searchDebounce])

    return (
        <div className="flex gap-3 mb-3 w-full">
            <SharedCategorySelect
                className="col"
                placeholder={t('common.select.category')}
                name="category"
                params={{required: true}}
                required={true}
                isClearable={true}
                isSearchable={false}
            />
            <AsyncRentalPoint name='rental_point' />
            <div className="col"></div>
            <FormControl
                rootclassname="col-3"
                icon="search"
                name="search"
                className="form-control"
                placeholder={t('common.input.search')}
            />
        </div>
    )
}
