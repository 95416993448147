import {Popover} from 'antd'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router'
import {Link} from 'react-router-dom'
import profileImage from 'src/assets/images/profile-icon.svg'
import {ORDER_STATUS_NAME} from 'src/components/shared/constants'
import {ImageField} from 'src/components/shared/image-loader/image'
import {OrderRequestListModel} from 'src/models/manager/order/order-request-list.model'
import settingService from 'src/services/settings.service'
import toDateTime from 'src/utils/date-time'
import {toPrice} from 'src/utils/price'
import {uniqByReduce} from 'src/utils/uniqueArray'
import {OrderStatus, PaymentStatus} from '../constants'
import {OrderCardDocument} from "./order-document"
import Icon from "src/components/shared/components/material-icon"
import {useTranslation} from "react-i18next"
import {toast} from "react-toastify"
import clsx from "clsx"

export function OrderCard(config: {order: OrderRequestListModel}) {
  const {t} = useTranslation()
  const order = config.order
  const navigate = useNavigate()
  const [collapsed, setCollapsed] = useState(true)
  const [color, setColor] = useState()

  const inventories = order.inventories.map(inventory => (
    <div
      key={inventory.id}
      style={{lineHeight: '20px'}}
      className={clsx(
        'mb-1 text-[13px] color-gray-500',
        ((inventory.started && order.receivements.length > 0 && !inventory.returned) || order.time_exceed) && 'color-red'
      )}
    >
      - {inventory.inventory_name} ({inventory.inventory_unique_id})
    </div>
  ))

  const user = (title: string, name: string) => (
    <div className="flex justify-between mb-2">
      <div className="text-sm font-medium">{title}</div>
      <span className="color-gray-500 text-[13px] text-end">{name}</span>
    </div>
  )

  const comment = order.comments.length > 0 && order.comments[order.comments.length - 1]

  const navigateOrder = () => {
    const selectedText = window.getSelection().toString()
    if (!selectedText) navigate(`/orders/${order.id}/all`)
  }

  const onIdCopy = e => {
    e.stopPropagation()
    navigator.clipboard.writeText(String(order.id))
    toast("Номер скопирован")
  }

  const onPhoneCopy = e => {
    e.stopPropagation()
    navigator.clipboard.writeText(String(order.client.phone))
    toast("Номер телефона скопирован")
  }

  const isNew = moment().diff(moment(order.created_at)) < 60 * 60 * 1000 && order.status === OrderStatus.REQUEST
  const clientIsLate = moment() > moment(order.rent_start) && order.status === OrderStatus.RESERVED

  useEffect(() => {
    const sub = settingService.config$.subscribe(config => setColor(config[order.status_color]))
    return () => sub.unsubscribe()
  }, [])

  return (
    <div className="order-card flex flex-col cursor-pointer" style={{backgroundColor: color}}>
      <div className="p-3" onClick={navigateOrder}>
        <div className="flex justify-between items-start text-sm gap-2 font-semibold">
          <div className="flex gap-1 scrollbar-hide" style={{overflowX: 'scroll', borderRadius: 8}}>
            <div className="order-card-status flex gap-1 items-center font-medium" style={{color: color}}>
              {t(ORDER_STATUS_NAME[order.status_color])}
              {isNew && <div className="btn btn-red btn-color-white text-xs font-medium p-1 py-0">NEW</div>}
            </div>
            {order.deleted && <div className="btn btn-red btn-color-white font-medium px-2 py-1">Архивирован</div>}
          </div>
          {order.id && (
            <button className="btn px-0 py-1 text-[13px] font-medium items-center color-white gap-1" onClick={onIdCopy}>
              <Icon icon="content_copy" /> №{order.id}
            </button>
          )}
        </div>
        <div className="flex items-start gap-2 mt-2">
          <Popover
            className={clsx('order-card-header-wrapper flex flex-row items-center gap-2 justify-between', clientIsLate && 'red-outlined')}
            content={!!order.rent_fact_start && <span className="text-nowrap">Факт. время начала - {toDateTime(order.rent_fact_start)}</span>}
          >
            <div className="flex flex-col gap-2">
              <div className="color-white">{t('orders.list.rent_start')}</div>
              <div className="color-white font-semibold text-sm">{toDateTime(order.rent_start, 'DD MMMM, HH:mm')}</div>
            </div>
            {clientIsLate && <div style={{width: 8, height: 8, borderRadius: '50%', backgroundColor: 'var(--color-red)'}} />}
          </Popover>
          <Popover
            className="order-card-header-wrapper"
            content={!!order.rent_fact_end && <span className="text-nowrap">Факт. время конца - {toDateTime(order.rent_fact_end)}</span>}
          >
            <div className="color-white">{t('orders.list.rent_end')}</div>
            <div className="color-white font-semibold text-sm">{toDateTime(order.rent_end, 'DD MMMM, HH:mm')}</div>
          </Popover>
        </div>
        <div className="flex items-start mt-2">
          {order.client ? (
            <Popover
              trigger="hover"
              overlayInnerStyle={{padding: 6}}
              content={() => (
                <div className="flex gap-1 flex-col">
                  <Link to={`/clients/${order.client.id}`} onClick={(e) => e.stopPropagation()} className="btn btn-gray-50 text-start py-1">
                    Перейти к клиенту
                  </Link>
                  <button className="btn btn-gray-50 text-start py-1" onClick={onPhoneCopy}>
                    Скопировать номер телефона
                  </button>
                </div>
              )} className="order-card-header-wrapper flex-row items-center">
              <Icon className="text-2xl color-white" icon="groups" />
              <div className="flex flex-col gap-1">
                <div className="color-white font-semibold text-sm">{order.client.name}</div>
                <div className="color-white font-normal text-sm">{order.client.phone}</div>
              </div>
            </Popover>
          ) : (
            <div className="order-card-header-wrapper flex-row items-center gap-2">
              <Icon className="text-2xl color-white" icon="groups" />
              <div className="color-white font-semibold text-sm">{t('orders.list.no_client')}</div>
            </div>
          )}
        </div>
      </div>

      <div className="order-card-content-wrapper">
        <div className="order-card-content flex flex-col gap-3" onClick={navigateOrder}>
          <div className="text-sm font-semibold">{t('orders.card.overall_title')}</div>
          <div className="flex justify-between text-[13px]">
            <div className="color-gray-500">{t('orders.card.price_title')}</div>
            <div className="color-black font-bold text-nowrap">
              {toPrice(order.price_discount)}
            </div>
          </div>
          <div className="text-[13px]">
            <div className="flex justify-between">
              <div className="color-gray-500">{t('orders.card.sum_title')}</div>
              <div className="color-black font-medium text-nowrap">{toPrice(order.price)}</div>
            </div>
            {Number(order.discount_amount) !== 0 && (
              <div className="flex justify-between text-[13px] mt-2">
                <div className="color-gray-500">{t('orders.card.discount_title')}</div>
                <div className="color-black font-medium text-nowrap">{toPrice(-order.discount_amount)}</div>
              </div>
            )}
          </div>

          {[PaymentStatus.PENDING, PaymentStatus.PARTLY_PAID].includes(order.payment_status) && (
            <div className="btn btn-red-8 btn-color-red w-full font-medium">
              {t('common.payment_status.not_paid')} ({toPrice(+order.price_discount - +order.paid_amount)})
            </div>
          )}

          {order.payment_status === PaymentStatus.PAID && (
            <div className="btn btn-green-8 btn-color-green w-full gap-2 font-medium">{t('common.payment_status.paid')}</div>
          )}

          {comment && (
            <div className="flex gap-2 flex-col order-card-content-comment shadow">
              <div className="flex items-center justify-between">
                <div className="flex gap-2 items-center text-sm font-bold">
                  <ImageField
                    src={comment.owner.avatar}
                    fallbackimage={profileImage}
                    className="client-avatar"
                    style={{objectFit: 'cover', width: 32, height: 32, borderRadius: '50%'}}
                  />
                  {comment.owner.first_name} {comment.owner.last_name}
                </div>
                {order.comments.length > 1 && (
                  <div
                    style={{padding: 4, borderRadius: '50%', width: 16, height: 16}}
                    className="flex items-center justify-center text-xs font-medium bg-red color-white"
                  >
                    {order.comments.length}
                  </div>
                )}
              </div>
              <div className="text-[13px] color-gray-400 overflow overflow line-clamp-2">{comment.body}</div>
            </div>
          )}

        </div>

        <div className="order-card-content" onClick={navigateOrder}>
          <div className="text-sm font-bold mb-3">{t('orders.card.inventory_title')} ({toPrice(order.price_inventory)})</div>
          {collapsed ? inventories.slice(0, 5) : inventories}
          {inventories.length > 5 && collapsed && (
            <div
              className="flex items-center gap-2 mt-3 color-gray-400 text-[13px]"
              onClick={e => {
                e.stopPropagation()
                setCollapsed(false)
              }}
            >
              <Icon icon="expand_more" />
              <span>{t('orders.list.show_more', {count: inventories.length - 5})}</span>
            </div>
          )}
          {!collapsed && (
            <div
              className="flex items-center gap-2 mt-3 color-gray-400 text-[13px]"
              onClick={e => {
                e.stopPropagation()
                setCollapsed(true)
              }}
            >
              <Icon icon="expand_less" />
              <span>{t('orders.list.collapse')}</span>
            </div>
          )}
        </div>

        {order.services.length > 0 && (
          <div className="order-card-content flex flex-col" style={{gap: 12}} onClick={navigateOrder}>
            <div className="text-sm font-bold">{t('orders.card.service_title')} ({toPrice(order.price_service)})</div>
            <div className="flex flex-col gap-1">
              {order.services.map(service => (
                <div key={service.id} className="mb-1 text-[13px] color-gray-500">
                  - {service.service} ({service.worker})
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="order-card-content flex-1" onClick={navigateOrder}>
          {order.reserved_by && user(t('orders.card.reserved_by'), order.reserved_by)}
          {order.issues.length > 0 && user(t('orders.card.issued_by'), uniqByReduce<string>(order.issues.map(item => item.user)).join(', '))}
          {order.receivements.length > 0 && user(t('orders.card.received_by'), uniqByReduce<string>(order.receivements.map(item => item.user)).join(', '))}
          {order.cancelled_by && user(t('orders.card.cancelled_by'), order.cancelled_by)}
        </div>

        <OrderCardDocument order={config.order} />
      </div>
    </div>
  )
}
