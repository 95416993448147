import HeaderContent from "src/components/header/header-content"
import integrationImage from 'src/assets/images/integrations.png'
import {useEffect, useState} from "react"
import {IntegrationModel} from "src/models/integration"
import integrationService from "src/services/integration"
import {ImageField} from "src/components/shared/image-loader/image"
import {Link} from "react-router-dom"
import Icon from "src/components/shared/components/material-icon"
import modalService from "src/components/modal/global/modal.service"
import {IntegrationConnection} from "./connection.modal"
import "./styles.scss"

function IntegrationBanner() {
    return <div className="integration-banner mb-4">
        <div className="flex gap-2 flex-col color-white integration-banner-text">
            <div className="text-3xl font-bold">Добавьте больше функций к yume.cloud через интеграции</div>
            <div className="text-[15px]">Отслеживайте показатели пунктов проката, получайте отзывы клиентов, отчеты и управляйте бизнесом эффективно</div>
        </div>
        <img src={integrationImage} className="integration-banner-image" alt="" />
    </div>
}

export function IntegrationComponent() {
    const [list, setList] = useState<IntegrationModel[]>([])

    const onConnect = (integration: IntegrationModel) => {
        modalService.open({component: <IntegrationConnection integration={integration} />})
    }

    useEffect(() => {
        const sub = integrationService.integrations$.subscribe(setList)
        return () => sub.unsubscribe()
    }, [])

    return <>
        <HeaderContent>
            <div className="text-2xl font-semibold header-content">Интеграции</div>
        </HeaderContent>

        <IntegrationBanner />

        <div className="integration-list">
            {list.map((integration) => (
                <div key={integration.code} className="card-wrapper flex justify-between flex-col gap-2" style={{height: 320}}>
                    <div style={{opacity: integration.disabled ? 0.5 : 1, pointerEvents: integration.disabled ? 'none' : 'inherit'}} className="flex gap-2 flex-col w-full">
                        <div className="flex gal-2 justify-between items-center">
                            <div className="integration-list-icon">
                                <ImageField
                                    draggable={false}
                                    src={integration.icon}
                                    style={{objectFit: 'contain', width: 32, height: 32}}
                                />
                            </div>
                            {integration.extra && 'is_yume_cloud' in integration.extra && integration.extra['is_yume_cloud'] && (
                                <span className="color-primary fw-semibold">от yume.cloud</span>
                            )}
                        </div>
                        <div className="text-xl font-bold">{integration.name}</div>
                        <div className="text-sm" style={{whiteSpace: 'pre-line'}}>{integration.comment}</div>
                        <hr className="color-gray-200 w-full" />
                    </div>
                    {integration.disabled ? (
                        <div className="btn btn-card fw-semibold w-full">
                            Скоро
                        </div>
                    ) : (
                        integration.connected ? (
                            <div className="flex gap-2 w-full">
                                <div className="btn btn-green-8 gap-1 text-[13px] btn-color-green fw-semibold col">
                                    <Icon icon="check" />
                                    Подключено
                                </div>
                                <Link to={integration.code} className="btn btn-black text-[13px] fw-semibold col">
                                    Перейти
                                </Link>
                            </div>
                        ) : (
                            <button onClick={() => onConnect(integration)} className="btn btn-primary btn-color-white fw-semibold w-full">
                                Подключить
                            </button>
                        )
                    )}
                </div>
            ))}
        </div >
    </>
}