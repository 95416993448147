import {useTranslation} from "react-i18next"
import {Outlet, useNavigate} from 'react-router-dom'
import Segmented from 'src/components/shared/segmented'
import {useCurrentRoute} from 'src/hooks/useCurrentRoute'

export default function ReportPayback() {
  const navigate = useNavigate()
  const page = useCurrentRoute(4)
  const {t} = useTranslation()

  return (
    <>
      <Segmented
        onChange={val => navigate(val)}
        value={page}
        segmentStyle={{backgroundColor: 'var(--color-gray-100)', marginBottom: 16}}
        options={[
          {label: t('metrics.inventory.pages.efficency'), value: 'efficency'},
          {label: t('metrics.inventory.pages.payback'), value: 'payback'},
        ]}
      />

      <Outlet />
    </>
  )
}
