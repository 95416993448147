import axios from 'axios'
import {ListModel} from '../../models/common'
import {ApiAbstract} from '../../abstract/api.abstract'
import {DetailOrderRequestModel} from 'src/models/manager/order/order-request-detail.model'
import {OrderRequestAmountModel, OrderRequestCountModel, OrderRequestListModel} from 'src/models/manager/order/order-request-list.model'
import {BehaviorSubject, Observable, switchMap} from 'rxjs'
import {DetailOrderRequestInventoryModel} from 'src/models/manager/order/order-request-inventory'
import {getAPI} from '../../interceptor'
import {OrderRequestReceiptModel} from "src/models/manager/order/order-request-receipt.model"
import {OrderRequestPauseModel} from "src/models/manager/order/order-request-pause.model"

export interface OrderAction {
  status: number
  order_inventories?: number[]
}

export class ManageOrdersService extends ApiAbstract {
  constructor() {
    super('v1/crm/requests')
  }

  refresh$: BehaviorSubject<void> = new BehaviorSubject<void>(null)

  public async getCount(params?: any): Promise<OrderRequestCountModel> {
    const response = await axios.get(this.getUrl('counts'), {params})
    return response.data
  }
  public async getAmount(params?: any): Promise<OrderRequestAmountModel> {
    const response = await axios.get(this.getUrl('amount'), {params})
    return response.data
  }
  public async list(params?: any): Promise<ListModel<OrderRequestListModel>> {
    const response = await axios.get(this.getUrl(), {params})
    return response.data
  }

  get(id: number): Observable<DetailOrderRequestModel> {
    return this.refresh$.pipe(switchMap(() => getAPI(axios.get(this.getUrl(id)))))
  }

  public async post(payload: any): Promise<DetailOrderRequestModel> {
    const response = await axios.post(this.getUrl(), payload)
    return response.data
  }
  public async patch(id: number, payload: any): Promise<DetailOrderRequestModel> {
    const response = await axios.patch(this.getUrl(id), payload)
    return response.data
  }
  public async delete(id: number): Promise<DetailOrderRequestModel> {
    const response = await axios.delete(this.getUrl(id))
    return response.data
  }
  public async pause(id: number): Promise<boolean[]> {
    const response = await axios.post(this.getUrl(`${id}/pause`))
    return response.data
  }
  public async pauseList(id: number): Promise<OrderRequestPauseModel[]> {
    const response = await axios.get(this.getUrl(`${id}/pause`))
    return response.data
  }
  public async restore(id: number): Promise<undefined> {
    const response = await axios.delete(this.getUrl(`${id}/archive`))
    return response.data
  }
  public async listInventories(id: number, params?: any): Promise<DetailOrderRequestInventoryModel[]> {
    const response = await axios.get(this.getUrl(`${id}/inventories`), {params})
    return response.data
  }
  public async onAction(id: number, payload: OrderAction): Promise<undefined> {
    const response = await axios.post(this.getUrl(`${id}/action`), payload)
    return response.data
  }
  public async onReceipt(id: number): Promise<OrderRequestReceiptModel> {
    const response = await axios.get(this.getUrl(`${id}/receipt`))
    return response.data
  }
  public async createBonus(id: number): Promise<undefined> {
    const response = await axios.post(this.getUrl(`${id}/create_bonus`))
    return response.data
  }
  public async deleteBonus(id: number): Promise<undefined> {
    const response = await axios.delete(this.getUrl(`${id}/delete_bonus`))
    return response.data
  }
}

const orderService = new ManageOrdersService()
export default orderService
