import {DatePicker} from "."
import type {Moment} from 'moment'
import React, {useEffect, useState} from "react"
import moment from "moment"
import {RangePickerProps} from "antd/es/date-picker/generatePicker/interface"
import tenantService, {TenantModel} from "src/services/tenant.service"
import Icon from "src/components/shared/components/material-icon"
const {RangePicker} = DatePicker

export interface RangePickerPropsV2 extends Omit<RangePickerProps<Moment>, 'picker'> { }

const _RangePicker = React.forwardRef<any, RangePickerPropsV2>((props, ref) => {
    const [tenant, setTenant] = useState<TenantModel>()

    const rangePresets: RangePickerPropsV2['presets'] = [
        {label: 'За текущий год', value: [moment().startOf('year'), moment().endOf('year').add(1, 'days')]},
        {label: 'За текущий месяц', value: [moment().startOf('month'), moment().endOf('month').add(1, 'days')]},
        {label: 'За прошлый месяц', value: [moment().startOf('month').add(-1, 'months'), moment().startOf('month')]},
        {label: 'За все время', value: [moment(tenant?.created_at), moment().endOf('year').add(1, 'days')]},
    ]

    useEffect(() => {
        const sub = tenantService.tenant$.subscribe(setTenant)
        return () => sub.unsubscribe()
    }, [])

    return <>
        <RangePicker
            {...props}
            format="DD-MM-YYYY"
            presets={rangePresets}
            ref={ref}
            allowEmpty={[false, false]}
            separator={<Icon icon="arrow_forward" className="text-xs" />}
        />
    </>
})

export default _RangePicker