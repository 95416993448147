import {FormControlMask} from 'src/components/shared/inputs/form-control-mask'
import {FormControl} from 'src/components/shared/inputs/form-control'
import {FormControlDate} from 'src/components/shared/inputs/form-control-date'
import {CustomFieldChoiceList} from "src/pages/settings/custom-field/field-choice"
import {useTranslation} from "react-i18next"

export default function ClientIndividualPassportComponent() {
  const {t} = useTranslation()
  const rules = {required: true}
  return (
    <>
      <div className="flex gap-2 mb-3 flex-col md:flex-row">
        <FormControlMask
          label={t('clients.form.document.individual.iin')}
          placeholder="000000000000"
          name="passport__individual.iin"
          mask="999999999999"
          rootclassname="col"
          className="form-control"
          required={rules.required}
          params={rules}
        />
        <FormControlDate
          label={t('clients.form.document.individual.birth_date')}
          name="passport__individual.birth_date"
          rootclassname="col"
          className="form-control"
          type="date"
        />
        <FormControl
          label={t('clients.form.document.individual.document_number')}
          placeholder={t('clients.form.document.individual.placeholder.document_number')}
          name="passport__individual.document_number"
          rootclassname="col"
          className="form-control"
        />
      </div>
      <div className="flex gap-2 mb-3 flex-col md:flex-row">
        <FormControl
          label={t('clients.form.document.individual.issuer_manual')}
          placeholder={t('clients.form.document.individual.placeholder.issuer_manual')}
          name="passport__individual.issuer_manual"
          rootclassname="col"
          className="form-control"
        />
        <FormControlDate
          label={t('clients.form.document.individual.issue_date')}
          type="date"
          name="passport__individual.issue_date"
          rootclassname="col"
          className="form-control"
        />
        <FormControlDate
          label={t('clients.form.document.individual.issue_date_end')}
          type="date"
          name="passport__individual.issue_date_end"
          rootclassname="col"
          className="form-control"
        />
      </div>
      <CustomFieldChoiceList prefix="passport__individual." className="mb-3" type="client_document" />
    </>
  )
}
