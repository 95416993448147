import {HTMLAttributes} from 'react'
import './tabs.scss'
import clsx from "clsx"

export interface TabPanelConfig {
  key: string | number
  label: JSX.Element | string
  active?: boolean
  disabled?: boolean
  onClick?: () => void
}

export interface TabConfig extends HTMLAttributes<HTMLDivElement> {
  items: TabPanelConfig[]
  onClick?: (TabPanelConfig) => void
  activeTab?: string | number
  rightChildren?: JSX.Element
}

export const TabHeader = (config: TabPanelConfig) => {
  const {label, active, disabled, onClick} = config
  const className = `tab-nav-link text-nowrap ${disabled ? 'disabled' : ''} ${active ? 'active' : ''}`
  return (
    <div className={className} onClick={onClick}>
      {label}
    </div>
  )
}

export const TabPanel = (config: TabConfig) => {
  return (
    <div className={clsx("flex items-end", config.className)}>
      <div className="tab scrollbar-hide border-bottom flex-1 px-0 pt-0 md:px-4 md:pt-2" style={config.style}>
        <div className="tab-nav gap-2 md:gap-8">
          {config.items.map(item => (
            <TabHeader
              {...item}
              key={item.key}
              active={config.activeTab && item.key === config.activeTab}
              onClick={config.onClick ? () => config.onClick(item) : undefined}
            />
          ))}
        </div>
      </div>
      {config.rightChildren}
    </div>
  )
}
