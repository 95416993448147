import {StylesConfig} from 'react-select'
import {invertColor} from "src/utils/color/color-invert"

export const colourStyles = (invalid: boolean): StylesConfig => {
  return {
    control: (styles, props) => ({
      ...styles,
      backgroundColor: props.isDisabled ? 'var(--color-gray-100)' : 'var(--color-white)',
      color: props.isDisabled ? 'var(--color-black)' : 'var(--color-black)',
      borderColor: invalid ? 'var(--color-red)' : 'var(--color-gray-100)',
      borderWidth: '1px !important',
      padding: '0 0',
      fontSize: 14,
      transition: '0.25s all',
      boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.04)',
      borderRadius: 7,
      cursor: 'pointer',
      opacity: 1,
      minHeight: 48,
      height: props.isMulti ? 'inherit' : 48,
      zIndex: 9,
      '&:focus': {
        borderColor: 'var(--color-gray-100)',
        boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.08)',
      },
      '&:hover': {
        borderColor: 'var(--color-gray-100)',
        boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.08)',
      },
    }),
    indicatorSeparator: (styles, props) => ({
      ...styles,
      width: 0,
    }),
    indicatorsContainer: (styles, props) => ({
      ...styles,
      paddingRight: 12,
      "@media only screen and (max-width: 768px)": {
        display: 'none'
      },
    }),
    valueContainer: (styles, {isMulti}) => ({
      ...styles,
      padding: isMulti ? '4px 8px' : 8,
      paddingLeft: isMulti ? 8 : 16,
      rowGap: 2,
      columnGap: 4,
      "@media only screen and (max-width: 768px)": {
        paddingLeft: isMulti ? 8 : 16,
        paddingRight: isMulti ? 8 : 16,
      },
    }),
    multiValue: (styles, props) => {
      const backgroundColor = (props.data as any)?.code
      return {
        ...styles,
        position: 'relative',
        backgroundColor: backgroundColor ? backgroundColor : 'var(--color-gray-100)',
        borderRadius: 4,
        margin: props.isMulti ? 4 : 0
      }
    },
    multiValueLabel: (styles, props) => {
      const backgroundColor = (props.data as any)?.code
      return {
        ...styles,
        color: backgroundColor ? invertColor(backgroundColor) : 'inherit',
      }
    },
    multiValueRemove: (styles, props) => ({
      ...styles,
      borderRadius: "50%",
      position: 'absolute',
      zIndex: 100000,
      padding: 1,
      top: -6,
      right: -6,
      minWidth: 10,
      minHeight: 10,
      backgroundColor: 'var(--color-white)',
      color: 'var(--color-black)',
      boxShadow: '0 1px 4px 0 rgba(35, 57, 66, 0.21)',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'var(--color-red)',
        color: 'var(--color-white)'
      }
    }),
    dropdownIndicator: (base, props) => ({
      ...base,
      padding: 4,
      transition: '0.25s all',
    }),
    singleValue: (styles, props) => ({
      ...styles,
      color: 'var(--color-black)',
      '&::placeholder': {
        color: 'var(--color-gray-300)',
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
      },
      zIndex: 9,
    }),
    menu: (styles, props) => ({
      ...styles,
      padding: 0,
      backgroundColor: 'var(--color-white)',
      boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.16)',
      zIndex: 82,
      borderRadius: 8,
      // "@media only screen and (max-width: 768px)": {
      //   minWidth: 120,
      //   position: 'absolute',
      //   bottom: 0,
      //   marginBottom: 0,
      //   borderBottomLeftRadius: 0,
      //   borderBottomRightRadius: 0,
      //   top: 'unset',
      //   width: '100vw'
      // }
    }),
    menuList: (styles, props) => ({
      ...styles,
      padding: 0,
      "@media only screen and (max-width: 768px)": {
        maxHeight: 'calc(100vh - 320px)'
      }
    }),
    menuPortal: (styles, {selectProps: {isSearchable}}: any) => {
      return {
        ...styles,
        padding: '0px 4px',
        backgroundColor: 'var(--color-white)',
        boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.16)',
        zIndex: 99999,
        borderRadius: 8,
        fontSize: 14,
        "@media only screen and (max-width: 768px)": {
          boxShadow: 'none',
          // backgroundColor: isSearchable ? 'unset' : 'rgb(0,0,0,0.3)',
          backgroundColor: 'rgb(0,0,0,0.3)',
          borderRadius: 0,
          position: 'absolute',
          width: '100vw',
          padding: 0,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      }
    },
    option: (styles, {isSelected, isFocused}) => {
      return {
        ...styles,
        borderRadius: 0,
        padding: '12px 16px',
        transition: '0.25s all',
        backgroundColor: isSelected ? 'var(--color-primary)' : (isFocused ? 'var(--color-primary-8)' : 'transparent'),
        color: isSelected ? 'var(--color-white)' : (isFocused ? 'var(--color-primary)' : 'var(--color-black)'),
        borderBottom: '1px solid var(--color-gray-100)',
        cursor: 'pointer',
        ':active': {
          backgroundColor: 'var(--color-primary-8)'
        },
        ':first-of-type': {
          marginTop: 0,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8
        },
        ':last-of-type': {
          borderBottom: 'none',
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8
        },
        '&:focus': {
          backgroundColor: isSelected ? 'var(--color-primary)' : 'var(--color-primary-8)',
          color: isSelected ? 'var(--color-white)' : 'var(--color-primary)'
        },
        '&:hover': {
          backgroundColor: isSelected ? 'var(--color-primary)' : 'var(--color-primary-8)',
          color: isSelected ? 'var(--color-white)' : 'var(--color-primary)'
        }
      }
    },
    placeholder: (styles, {isMulti}) => ({
      ...styles,
      color: 'var(--color-gray-400)',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      paddingLeft: isMulti ? 8 : 0,
      margin: 0,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    }),
  }
}