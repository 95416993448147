import axios, {AxiosResponse} from 'axios'
import {Observable, from} from 'rxjs'
import loadingService from "src/services/loading.service"
import {extractHttpErrorMessage} from 'src/utils/url.utils'
import modalService from "../components/modal/global/modal.service"
import tokenService from '../services/token.service'
import {axiosResponce} from "./axios-response"
import {isNil} from "src/utils/isNil"
import {TenantSubscriptionModal} from "src/components/modal/components/subscription"
import subscriptionModalService from "src/services/subscription.service"
import authService from "src/services/auth.service"

const protocol = process.env.REACT_APP_API_PROTOCOL
const host = window.location.host

const subdomainPattern = /(https?:\/\/)([^.]+)\.(.*)/

axios.defaults.baseURL = `${protocol}://${host.replace("3000", "8000")}`.replace(subdomainPattern, '$1$2.api.$3')

export function getAPI<T>(promise: Promise<AxiosResponse<T, any>>): Observable<T> {
  return from(promise).pipe(axiosResponce)
}

let refreshing: boolean = false

axios.interceptors.request.use(
  config => {
    const skip_loading = config && config.params && config.params.skip_loader === true
    if (!skip_loading) loadingService.setLoading(config.url)
    if (config.params) {
      config.params = Object.fromEntries(Object.keys(config.params).map(key => [
        key,
        Array.isArray(config.params[key]) ? config.params[key].join(',') : config.params[key]
      ]))
    }
    const token = tokenService.getAccess
    if (isNil(token) && config.url === '/v1/crm/constants/') return Promise.reject(config)
    config.headers['Accept-Language'] = 'ru'
    if (token) config.headers['Authorization'] = `Bearer ${token}`
    return config
  },
  e => Promise.reject(e),
)

axios.interceptors.response.use(
  res => {
    loadingService.removeLoading(res.config.url)
    return res
  },
  err => {
    loadingService.removeLoading(err?.config?.url)
    const originalConfig = err.config
    const urlRefresh = originalConfig?.url === '/v1/auth/refresh/'
    const urlLogin = originalConfig?.url === '/v1/auth/login/'
    const notAuth = err.response && tokenService.getRefresh && err.response.status === 401

    if (!urlRefresh && !urlLogin && notAuth && refreshing) {
      return axios(originalConfig)
    }

    if (!urlRefresh && !urlLogin && notAuth && !refreshing) {
      refreshing = true

      return axios.post('/v1/auth/refresh/', {refresh: tokenService.getRefresh}).then((res) => {
        const {access} = res.data
        tokenService.updateAccess(access)
        return axios(originalConfig)
      }).catch((_error) => {
        tokenService.removeToken()
        return Promise.reject(_error)
      }).finally(() => refreshing = false)
    }

    let message: JSX.Element | string = 'Ошибка'
    const data = err.response.data

    if (err.response.status === 0) {
      message = originalConfig?.url
        ? <div className="py-2">Доступ к <code>{originalConfig?.url}</code> заблокирован политикой CORS. Подробности смотрите в консоли браузера.</div>
        : <div className="py-2">Доступ к HTTP-адресу заблокирован политикой CORS. Подробности смотрите в консоли браузера.</div>
    } else if (err.response.status >= 500) {
      message = <div className="py-2">
        Возникла ошибка сервера. Пожалуйста, обратитесь за помощью к системному администратору.
      </div>
    } else if (err.response.status === 404) {
      message = <div className="py-2">Не найдено</div>
    } else if (err.response.status === 409) {
      message = <div className="py-2">
        Невозможно удалить. Этот элемент связан с текущими или прошлыми арендами. Чтобы удалить его, сначала удалите все аренды, в которых он задействован.
      </div>
    } else if (data?.detail === 'subscription_limit') {
      message = null
      subscriptionModalService.setShow(true)
    } else if (data?.code === "token_not_valid") {
      tokenService.removeToken()
      authService.logged$.next(tokenService.hasToken)
      modalService.closeAllModal()
      return Promise.reject(err)
    } else {
      message = extractHttpErrorMessage(err.response.data)
    }

    if (originalConfig?.params?.skip_error_handling === true) {
      return Promise.reject(err)
    }

    if (message) {
      modalService.open({
        component: (
          <div className="flex items-center justify-center text-center">
            {message}
          </div>
        ),
        props: undefined
      })
    }

    return Promise.reject(err)
  },
)

export default axios
