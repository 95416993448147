import FormSelectAsync, {FormSelectAsyncConfig} from "../../inputs/form-select-async.list"
import {firstValueFrom} from "rxjs"
import {InventoryStateStatus} from "src/models/manager/constants"
import {useTranslation} from "react-i18next"
import discountService from "src/services/discount/discount.service"
import {DiscountModel} from "src/models/manager/discount/discount.model"
import {useEffect, useMemo, useState} from "react"
import tenantService, {TenantLimitModel} from "src/services/tenant.service"

type RemovalKeys = 'listOptions' | 'getValue' | 'getOptionLabel' | 'getOptionValue' | 'placeholder'

export function SharedDiscountTypeSelect(config: Omit<FormSelectAsyncConfig<InventoryStateStatus>, RemovalKeys> & {type?: undefined | 'discount' | 'promocode'}) {
    const {t} = useTranslation()
    const [limits, setLimits] = useState<TenantLimitModel>(undefined)
    const limit = !useMemo(() => limits && limits.discount ? limits.discount : false, [limits])

    useEffect(() => {
        const sub = tenantService.tenant$.subscribe(({limits}) => setLimits(limits))
        return () => sub.unsubscribe()
    }, [])

    return <FormSelectAsync<DiscountModel>
        {...config}
        placeholder={t('common.select.placeholder.discount')}
        listOptions={() => (
            config.type === 'discount' ? discountService.discounts.discount :
                config.type === 'promocode' ? discountService.discounts.promocode :
                    discountService.discounts$
        )}
        getValue={(id: number) => firstValueFrom(discountService.get(id))}
        getOptionLabel={(option: DiscountModel) => option ? `${option.name} (${option.discount_type === 1 ? `-${option.discount}` : `-${option.discount}%`})` : null}
        getOptionValue={(option: DiscountModel) => (option ? String(option.id) : null)}
    />
}