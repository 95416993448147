import {ActionMeta, GroupBase, OptionsOrGroups, Props, components} from 'react-select'
import {useFormContext, Controller, RegisterOptions} from 'react-hook-form'
import {colourStyles} from './form-select-color-style'
import {CSSProperties, useEffect, useState} from 'react'
import {ListModel} from 'src/models/common'
import {isNil} from 'src/utils/isNil'
import modalService, {ModalConfig} from "src/components/modal/global/modal.service"
import {AsyncPaginate} from "react-select-async-paginate"
import {SelectMenu, SelectOption} from "./select/common"

export interface FormSelectAsyncConfig<T> extends Props {
  label?: string
  name: string
  params?: RegisterOptions<any, any>
  listOptions: (params) => Promise<ListModel<T>>
  getValue: (id: number | string) => Promise<T>
  style?: CSSProperties
  modalProps?: ModalConfig
  disabled?: boolean
}

export default function FormSelectAsyncPagination<T>(config: FormSelectAsyncConfig<T>) {
  const {control, watch} = useFormContext()
  const [selectedOption, setSelectedOption] = useState<T>(null)

  const onModal = config.modalProps ? () => modalService.open(config.modalProps) : undefined

  useEffect(() => {
    if (watch(config.name)) {
      config.getValue(watch(config.name)).then(setSelectedOption)
    }
  }, [watch(config.name)])

  const loadOptions = async (search: string, prevOptions: OptionsOrGroups<T, GroupBase<T>>, {page = 1}: {page: number}) => {
    const response = await config.listOptions({search, page, skip_loader: true})
    return {
      options: response.results,
      hasMore: !isNil(response.next),
      additional: {page: page + 1}
    }
  }

  return (
    <div className={config.className} style={config.style}>
      {config.label && (
        <div className="flex gap-2 mb-2">
          <label className="overflow line-clamp-1 text-[13px] color-gray-400">{config.label}</label>
          {config.required && <div className="form-required"></div>}
        </div>
      )}
      <div className="flex gap-2">

        <Controller
          name={config.name}
          control={control}
          rules={config.params}
          render={({field: {onBlur, value, onChange, ref}, fieldState}) => (
            <AsyncPaginate
              styles={colourStyles(!isNil(fieldState.error))}
              value={value ? selectedOption : null}
              loadOptions={loadOptions}
              additional={{page: 1}}
              debounceTimeout={500}
              onBlur={onBlur}
              isSearchable={false}
              isClearable
              {...config}
              onChange={(val: T, action: ActionMeta<any>) => {
                setSelectedOption(val)
                onChange(config.getOptionValue(val))
                if (!isNil(config.onChange)) config.onChange(val, action)
              }}
              noOptionsMessage={() => 'пусто'}
              loadingMessage={() => 'загрузка...'}
              selectRef={ref}
              isDisabled={config.disabled}
              components={{
                Menu: !isNil(onModal) ? params => <SelectMenu {...params} onModal={onModal} /> : components.Menu,
                Option: params => <SelectOption {...params} />,
                ...config.components,
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  danger: "rgb(248, 52, 52)",
                  dangerLight: "rgb(248, 52, 52)",
                  primary: "rgb(161, 101, 253, 1)",
                  primary25: "rgb(161, 101, 253, 0.08)",
                  primary50: "rgb(161, 101, 253, 0.20)",
                  primary75: "rgb(161, 101, 253, 0.8)",
                },
              })}
            />
          )}
        />
      </div>
    </div>
  )
}
