import Table from "src/components/shared/antd-custom/table"
import type {ColumnsType} from 'antd/es/table'
import {SyntheticEvent, useContext, useEffect, useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {Link, useNavigate} from 'react-router-dom'
import rentalPointService from "src/components/modal/global/rental-point.service"
import PaginationFormComponent from 'src/components/shared/pagination/pagination-form'
import useQueryParams from 'src/hooks/useQuertParams'
import {EMPTY_LIST, ListModel} from 'src/models/common'
import {LabelModel} from "src/models/label"
import {CategoryModel, InventoryTarifTimePeriod} from 'src/models/manager/constants'
import {InventorySetItemModelV2, InventorySetModel, InventorySetPriceModel} from 'src/models/manager/inventory/inventory-set.model'
import inventorySetService from 'src/services/inventory/inventory-set.service'
import {useStore} from 'src/store/storeHooks'
import {getPipe} from 'src/utils/constant'
import {hexToRgbA} from "src/utils/color/hex-to-rgba"
import {isNil} from 'src/utils/isNil'
import objectFilter from 'src/utils/object.filter'
import {toPrice} from 'src/utils/price'
import {SettingConfig} from "src/models/setting-config"
import settingService from "src/services/settings.service"
import Icon from "src/components/shared/components/material-icon"
import HeaderContent from "src/components/header/header-content"
import ProductSetFilterComponent, {ProductSetFilterModel} from "./filter"
import {colorPaletes} from "src/components/shared/constants"
import {TableRowSelection} from "antd/es/table/interface"
import {ControlCheckbox} from "src/components/shared/inputs/control-checkbox"
import modalService from "src/components/modal/global/modal.service"
import {ConfirmModal} from "src/components/modal/global/confirmModal"
import {FormControlEntity} from "src/components/shared/inputs/form-control-entity"
import {useTranslation} from "react-i18next"
import {ConfigContext} from "src"


function InventorySetAddCell({
    group,
    onSelect
}: {
    group: InventorySetModel,
    onSelect: (group: InventorySetModel, count?: number) => void
}) {
    const {t} = useTranslation()
    const form = useForm({defaultValues: {count: 1}})
    const onSubmit = form.handleSubmit(payload => onSelect(group, payload.count))

    return <FormProvider {...form}>
        <div className="flex gap-2 align-center">
            <FormControlEntity name="count" maxcount={50} rootclassname="flex justify-center items-center" />
            <button className="btn btn-primary color-white" onClick={onSubmit}>{t('common.add')}</button>
        </div>
    </FormProvider>
}

export default function ProductSetList(config: {
    onSelect?: (item: InventorySetModel, count?: number) => Promise<number[] | void>
    params?: any
}) {
    const {t} = useTranslation()
    const {searchParams, setSearchParams} = useQueryParams()
    const archived = searchParams.archived ? searchParams.archived.toLowerCase() === 'true' : false
    const {settings} = useContext(ConfigContext)
    const [list, setList] = useState<ListModel<InventorySetModel>>(EMPTY_LIST)
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([])
    const form = useForm<ProductSetFilterModel>({
        defaultValues: {
            page: 1,
            pageSize: 10,
            rental_point: rentalPointService.filterPoint,
            search: '',
            ...searchParams,
            ...config.params,
            archived
        }
    })
    const {watch, reset, setValue} = form
    const {constants} = useStore(({app}) => app)
    const navigate = useNavigate()
    const values = watch()
    const isArchived = Boolean(values.archived)

    const columns: ColumnsType<InventorySetModel> = [
        {
            width: 24,
            title: '№',
            key: 'id',
            dataIndex: 'id',
            render: id => id,
        },
        {
            width: 180,
            title: t('set.table.name'),
            dataIndex: 'name',
            key: 'name',
            render: name => name,
            sorter: true,
        },
        {
            width: 64,
            title: t('set.table.category'),
            dataIndex: 'category',
            key: 'category',
            render: category => getPipe(category, constants.INVENTORY_CATEGORIES, (val: CategoryModel) => val.name),
            sorter: true,
        },
        {
            width: 64,
            title: t('set.table.unique_id'),
            dataIndex: 'unique_id',
            key: 'unique_id',
            render: unique_id => unique_id,
            sorter: true,
        },
        {
            width: 64,
            title: t('set.table.status'),
            key: 'status',
            render: (_, set) => {
                const isFree = set.items.every(group => group.available_cnt >= group.count)
                const status = isFree ? 0 : 2
                const color = colorPaletes[status]
                return (
                    <span className="btn py-1 px-2 mx-auto text-[13px]" style={{color, backgroundColor: hexToRgbA(color, 0.2)}}>
                        {getPipe(status, constants.INVENTORY_STATUS, (val: LabelModel) => val.label)}
                    </span>
                )
            },
            sorter: true,
        },
        {
            width: 180,
            title: t('set.table.prices'),
            dataIndex: 'prices',
            key: 'prices',
            render: (prices: InventorySetPriceModel[]) => <div className="flex gap-1 flex-col">
                {prices.map(price =>
                    <li key={price.id} className="flex items-center justify-between gap-2">
                        <span className="overflow line-clamp-1">{price.name ? price.name : getPipe(price.period_id, constants.TARIF_TIME_PERIODS, (val: InventoryTarifTimePeriod) => val.name)}</span>
                        <span className="text-nowrap btn py-1 px-2 text-sm font-semibold btn-icon">{toPrice(price.price)}</span>
                    </li>
                )}
            </div>,
        },
        {
            width: 180,
            title: t('set.table.items'),
            key: 'items',
            dataIndex: 'items',
            render: (items: InventorySetItemModelV2[]) => <div className="flex gap-1 flex-col">
                {items.map(group =>
                    <li className="flex items-center justify-between gap-2" key={group.id}>
                        <span className="overflow line-clamp-1">{group.group_name}</span>
                        <span className="text-nowrap btn py-1 px-2 text-[13px] btn-icon">{group.inventories_cnt - group.available_cnt} / {group.inventories_cnt}</span>
                    </li>
                )}
            </div>
        },
    ]

    const customColumns: ColumnsType<InventorySetModel> = settings ? settings.custom_fields.inventory_set
        .filter(field => field.table).map(field => (
            {
                width: 64,
                title: field.label,
                key: field.name,
                render: (_, set) => set && set.extra && set.extra[field.name],
            }
        )) : []

    const actionColumn: ColumnsType<InventorySetModel> = [
        {
            width: 64,
            title: '',
            key: "action",
            render: (_, group) => <InventorySetAddCell
                group={group}
                onSelect={(group, count) => {
                    config.onSelect(group, count).then((ids: number[]) => {
                        if (!ids) return
                        const excludeIds = values?.exclude_ids || ""
                        reset({...values, exclude_ids: [...(excludeIds !== '' ? excludeIds.split(',') : []), ...ids].join(',')})
                    })
                }}
            />,
        }
    ]

    const listSets = async (params: any) => inventorySetService
        .list(params).then(setList)
        .catch(e => e.response.status === 404 && setValue('page', 1))

    const onMultiInventorySetArchiveModal = async () => {
        const onConfirm = () => Promise.all(selectedRowKeys.map(id => inventorySetService.archive(id))).then(() => listSets(values)).then(() => setSelectedRowKeys([]))
        modalService.open({
            component: <ConfirmModal
                delete={!isArchived}
                confirm_text={t(isArchived ? "common.archive.return" : "common.archive.archive")}
                message={t(isArchived ? 'common.archive.to_return.set' : 'common.archive.to_archive')}
                onConfirm={onConfirm}
            />
        })
    }

    const rowSelection: TableRowSelection<InventorySetModel> = {
        selectedRowKeys,
        onChange: (keys: number[]) => setSelectedRowKeys(keys),
        columnWidth: 48,
        columnTitle: (
            <ControlCheckbox
                className="form-check-input"
                checked={selectedRowKeys.length !== 0 && selectedRowKeys.length === list.results.length}
                indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length !== list.results.length}
                onChange={() => setSelectedRowKeys(prev => prev.length === 0 ? list.results.map(obj => obj.id) : [])}
            />
        ),
        renderCell: (val: boolean, inventory: InventorySetModel) => (
            <ControlCheckbox
                className="form-check-input"
                checked={val}
                onClick={(e) => e.stopPropagation()}
                onChange={() => setSelectedRowKeys(prev => prev.includes(inventory.id) ? prev.filter(k => k !== inventory.id) : [...prev, inventory.id])}
            />
        )
    }

    useEffect(() => {
        listSets(values)
        const sub = watch((params, {name}) => {
            if (name !== 'search') listSets(params)
            const query = objectFilter(params)
            if (isNil(config.onSelect)) setSearchParams(query)
        })
        return () => {
            sub.unsubscribe()
            setList(EMPTY_LIST)
        }
    }, [])

    return (
        <FormProvider {...form}>
            <HeaderContent>
                <div className="flex items-center justify-between gap-2 header-content">
                    <div className="text-2xl font-semibold">{t('set.header')}</div>
                    <Link to="add" className="btn btn-primary btn-color-white justify-start gap-2 font-semibold text-nowrap">
                        <Icon className="text-lg" icon="add" />
                        {t('set.add')}
                    </Link>
                </div>
            </HeaderContent>

            <ProductSetFilterComponent />

            <Table
                title={isNil(config.onSelect) ? () => (
                    <div className="flex justify-end items-center">
                        <div className="flex gap-3">
                            {selectedRowKeys.length > 0 && <>
                                <button className="btn btn-card gap-2" onClick={onMultiInventorySetArchiveModal}>
                                    <Icon icon="archive" />
                                    <span className="font-medium text-[13px]">{t(isArchived ? 'common.archive.return' : 'common.archive.archive')}</span>
                                </button>
                                <div className="vr color-gray-200" style={{margin: '12px 0'}}></div>
                            </>}
                            <button
                                className={`btn btn-card ${isArchived ? 'light' : ''} gap-2`}
                                onClick={() => {
                                    setValue('archived', !isArchived)
                                    setSelectedRowKeys([])
                                }}
                            >
                                <Icon icon="package_2" />{t('common.archive.label')}
                            </button>
                        </div>
                    </div>
                ) : undefined}
                rowKey={obj => obj.id}
                rowSelection={isNil(config.onSelect) ? rowSelection : undefined}
                onRow={set => ({
                    onClick: (e: SyntheticEvent) => {
                        if (isNil(config.onSelect)) {
                            navigate(`/inventory-sets/${set.id}`)
                            return
                        }
                        if (!isNil(config.onSelect)) return
                    },
                })}
                columns={config.onSelect ? [...columns, ...customColumns, ...actionColumn] : [...columns, ...customColumns]}
                dataSource={list.results}
            />
            <PaginationFormComponent className="mt-4" count={list.count} />
        </FormProvider>
    )
}
