import axios from 'axios'
import {BehaviorSubject, Observable, from, shareReplay, switchMap} from "rxjs"
import {axiosResponce} from '../interceptor/axios-response'
import tokenService from "./token.service"

export interface TokenModel {
  access: string
  refresh?: string
}

export interface ProfileModel {
  id: number
  last_login: string
  username: string
  first_name: string
  last_name: string
  email: string
  group: number
  is_staff: boolean
  is_sublease: boolean
  is_superuser: boolean
  point: number
  user_permissions: number[]
  perms: string[]
  avatar: string

  password?: string
}

class AuthService {
  refreshing: boolean = false

  logged$ = new BehaviorSubject(tokenService.hasToken)

  getProfile$ = new BehaviorSubject<void>(null)
  getProfile = () => this.getProfile$.next()

  profile$: Observable<ProfileModel> = this.getProfile$.pipe(
    switchMap(() => from(axios.get<ProfileModel>('/v1/auth/profile/')).pipe(axiosResponce)),
    shareReplay(1)
  )

  public async updateProfile(payload: any): Promise<ProfileModel> {
    const res = await axios.patch('/v1/auth/profile/', payload)
    return res.data
  }

  public async login(username: string, password: string): Promise<TokenModel> {
    const res = await axios.post('/v1/auth/login/', {username, password})
    tokenService.setToken(res.data as TokenModel)
    this.logged$.next(tokenService.hasToken)
    this.getProfile()
    return res.data
  }

  public async crossTokenLogin(token: string): Promise<TokenModel> {
    const res = await axios.post('/v1/auth/cross_login/', {token})
    tokenService.setToken(res.data as TokenModel)
    this.logged$.next(tokenService.hasToken)
    this.getProfile()
    return res.data
  }

  public async logout(): Promise<TokenModel> {
    const res = await axios.post('/v1/auth/logout/', {refresh: tokenService.getRefresh})
    tokenService.removeToken()
    this.logged$.next(tokenService.hasToken)
    return res.data
  }

  public async refresh(payload: any): Promise<TokenModel> {
    this.refreshing = true
    const res = await axios.post(`v1/auth/refresh/`, payload)
    return res.data
  }
}

export default new AuthService()
