import {PropsWithChildren, useEffect, useState} from 'react'
import rentalPointService from "src/components/modal/global/rental-point.service"
import {SharedRentalPointSelect} from "src/components/shared/components/select/rental-point"
import {RentalPointModel} from "src/models/manager/constants"
import authService, {ProfileModel} from "src/services/auth.service"

export function AsyncRentalPoint({name, label, params}: PropsWithChildren<{name: string, label?: string, params?: any}>): JSX.Element {
  const [profile, setProfile] = useState<ProfileModel>(null)

  useEffect(() => {
    const sub = authService.profile$.subscribe(setProfile)
    return () => sub.unsubscribe()
  }, [])

  return profile && profile.is_superuser && (
    <SharedRentalPointSelect
      className="col"
      label={label}
      name={name}
      isSearchable={true}
      isClearable={true}
      onChange={(val: RentalPointModel) => rentalPointService.setFilterPoint(val)}
      params={params}
    />
  )
}
