import React, {useMemo} from 'react'

interface InventoryItem {
  label: string
  value: any
  className?: string
}

interface InventorySection {
  values: InventoryItem[]
  title?: string
  className?: string
}

const Card: React.FC<InventorySection> = ({title, values, className}) => (
  <div className={className}>
    {title && <label className="overflow line-clamp-1 text-sm color-gray-400 mb-2">{title}</label>}
    <div className="card-bio bg-white flex flex-row">
      {values.map((value, index) => (
        <div className={`flex flex-col mx-1 ${value?.className}`} key={index}>
          <label className="font-bold text-lg mb-2">{value.value}</label>
          <label className="text-sm color-gray-400">{value.label}</label>
        </div>
      ))}
    </div>
  </div>
)

const CardWrapper = ({cardSectionData = [], className = 'mb-3'}: {cardSectionData: InventorySection[], className?: string}) => {
  const inventorySections = useMemo(
    () => cardSectionData.map((data, index) => <Card key={index} title={data.title} values={data.values} className={data?.className} />),
    [cardSectionData],
  )

  return <div className={`flex flex-wrap items-stretch ${className}`} style={{columnGap: 24, rowGap: 8}}>{inventorySections}</div>
}

export default CardWrapper
