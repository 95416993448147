import {useEffect} from 'react'
import {useFormContext} from 'react-hook-form'
import {AsyncRentalPoint} from "src/abstract/async-rental-point"
import {FormControl} from 'src/components/shared/inputs/form-control'
import {useDebounce} from 'src/hooks/useDebounce'
import {ListParams} from 'src/models/common'
import {SharedCategorySelect} from "src/components/shared/components/select/category"
import {useTranslation} from "react-i18next"
import {CustomFieldFilterList} from "src/pages/settings/custom-field/field-filter"

export interface InventoryGroupFilterForm extends ListParams {
    rental_point: number
    search: string
    category: number
    type: number
    archived: boolean
}

export default function InventoryGroupListFilterComponent() {
    const form = useFormContext<InventoryGroupFilterForm>()
    const {reset, watch} = form
    const {t} = useTranslation()
    const values = watch()

    const searchDebounce = useDebounce(watch('search'), 500)
    useEffect(() => reset({...values, page: 1, search: searchDebounce}), [searchDebounce])

    return <>
        <div className="flex gap-2 mb-3">
            <div className="flex gap-2 col-3">
                <SharedCategorySelect
                    className="col"
                    placeholder={t('common.select.category')}
                    name="category"
                    isClearable={true}
                    isSearchable={false}
                />
            </div>
            <AsyncRentalPoint name='rental_point' />
            <div className="col"></div>
            <FormControl
                icon="search"
                rootclassname="col-3"
                name="search"
                className="form-control"
                placeholder={t('common.input.search')}
            />
        </div>

        <CustomFieldFilterList className="mb-3" prefix="extra__" type="inventory" />
    </>
}
