import {CategoryModel} from "src/models/manager/constants"
import FormSelectAsync, {FormSelectAsyncConfig} from "../../inputs/form-select-async.list"
import {Observable, firstValueFrom, map} from "rxjs"
import appService from "src/services/app.service"
import {ConfigAddOrEdit} from "src/pages/config/config-add-or-edit"
import modalService, {ModalConfig} from "src/components/modal/global/modal.service"
import GenericEntityService from 'src/services/generic-entity.service'
import {FormControl} from "../../inputs/form-control"

type RemovalKeys = 'listOptions' | 'getValue' | 'getOptionLabel' | 'getOptionValue' | 'modalProps'
const inventoryCategories: Observable<CategoryModel[]> = appService.getConstant('INVENTORY_CATEGORIES')

interface CategorySelectConfig extends Omit<FormSelectAsyncConfig<CategoryModel>, RemovalKeys> {
    addOption?: boolean
}

export function SharedCategorySelect(config: CategorySelectConfig) {
    const service = new GenericEntityService<CategoryModel>('v1/crm/categories')

    const onCreate = async payload => {
        payload.comment = payload.comment || null
        const res = await service.post(payload)
        appService.loadConstants()
        return res
    }

    const modalProps: ModalConfig = {
        props: {size: 'lg', backdrop: true},
        component: (
            <ConfigAddOrEdit<CategoryModel>
                onSubmit={inventory => onCreate(inventory).then(() => modalService.closeModal())}
                buttonTitle="Добавить"
                title="Новая категория инвентаря"
                mode="add"
            >
                <FormControl
                    name="name"
                    label="Название"
                    className="form-control"
                    rootclassname="col"
                    params={{required: true}}
                    required
                />
            </ConfigAddOrEdit>
        )
    }

    return <FormSelectAsync<CategoryModel>
        {...config}
        isSearchable
        modalProps={config.addOption && modalProps}
        listOptions={() => inventoryCategories}
        getValue={(id: number) => firstValueFrom(inventoryCategories.pipe(map(list => list.find((obj: CategoryModel) => obj.id === +id))))}
        getOptionLabel={(option: CategoryModel) => (option ? String(option.name) : null)}
        getOptionValue={(option: CategoryModel) => (option ? String(option.id) : null)}
    />
}