import {Outlet} from 'react-router-dom'
import {useContext} from 'react'
import HeaderContent from "src/components/header/header-content"
import {ConfigContext} from "src"

export default function DeliveryWrapperComponent() {
    const {settings} = useContext(ConfigContext)

    return settings && <>
        <HeaderContent>
            <div className="flex justify-between align-content-center header-content">
                <div className="flex gap-2 items-center">
                    <div className="text-2xl font-semibold">Доставка</div>
                </div>
            </div>
        </HeaderContent>

        <Outlet context={settings} />
    </>
}
