import {InputHTMLAttributes} from 'react'
import {RegisterOptions, useFormContext} from 'react-hook-form'
import {getNestedObject} from "src/utils/get-nested-object"
import {isNil} from "src/utils/isNil"
import Icon from "src/components/shared/components/material-icon"

interface FormControlConfig extends InputHTMLAttributes<HTMLInputElement> {
  id?: any
  rootclassname?: string
  name: string
  params?: RegisterOptions<any, any>
  label?: string
  className?: string
  icon?: string
  addonright?: JSX.Element | string
  number_max?: number
  number_only?: number
  number_decimal?: number
}

export function FormControl(config: FormControlConfig) {
  const {register, formState: {errors}} = useFormContext()
  const error = getNestedObject(errors, config.name)
  const className = `${config.className ? config.className : ''} ${Object.keys(error).length !== 0 ? 'invalid' : ''}`
  const number_decimal: number = isNil(config.number_decimal) ? 0 : config.number_decimal

  const onInput = event => {
    if (config.number_only === 1) {
      let num = event.target.value.replace(/[^0-9.,]/, '').replace(',', '.')
      if (number_decimal === 1 && config.number_max) num = String(Math.min(+num, config.number_max))
      if (event.target.value === '') return
      const split = num.split('.')
      if (num === '.' && number_decimal === 1) {
        event.target.value = "0."
        return
      }
      if (split.length > 2) {
        event.target.value = num.slice(0, -1)
        return
      }
      if (split.length > 1 && split[1].length > 2) {
        event.target.value = num.slice(0, -1)
        return
      }
      if (Number.isInteger(+num) || num % 1 !== 0) {
        event.target.value = num
        return
      }
    }
    // setValue(config.name, event.target.value, {shouldDirty: true, shouldValidate: true, shouldTouch: true})
  }

  return (
    <div className={config.rootclassname || ''}>
      {config.label && (
        <div className="flex gap-2 mb-2">
          <label className="overflow line-clamp-1 text-[13px] color-gray-400">{config.label}</label>
          {config.required && <div className="form-required"></div>}
        </div>
      )}

      <div className="input-group">
        {config.icon && <Icon className="input-group-icon color-gray-400" icon={config.icon} />}
        <input
          {...config}
          {...register(config.name, config.params)}
          onInput={onInput}
          className={className}
          autoComplete="off"
        />
        {config.addonright && <div className="input-group-append">{config.addonright}</div>}
      </div>
    </div>
  )
}