import {Outlet, useNavigate} from 'react-router'
import {TabConfig, TabPanel, TabPanelConfig} from 'src/components/shared/tabs/tabs'
import {useCurrentRoute} from 'src/hooks/useCurrentRoute'
import HeaderContent from "src/components/header/header-content"
import {useEffect, useState} from "react"
import authService, {ProfileModel} from "src/services/auth.service"
import {useTranslation} from "react-i18next"

export default function ReportComponent() {
  const navigate = useNavigate()
  const activeRoute = useCurrentRoute(3)
  const {t} = useTranslation()
  const [profile, setProfile] = useState<ProfileModel>()

  const hasPerm = (key: string) => profile.perms.some(perm => perm.includes(key) || perm.includes('metricsmodel')) || profile.is_superuser

  const tabsConfig: TabConfig = profile && {
    items: [
      {key: 'sales', label: t('metrics.pages.sales'), disabled: !hasPerm('metricsfinance')},
      {key: 'clients', label: t('metrics.pages.clients'), disabled: !hasPerm('metricsclient')},
      {key: 'managers', label: t('metrics.pages.managers'), disabled: !hasPerm('metricsmanager')},
      {key: 'services', label: t('metrics.pages.services'), disabled: !hasPerm('metricsservice')},
      {key: 'sublease', label: t('metrics.pages.sublease'), disabled: !hasPerm('metricssublease')},
      {key: 'inventory', label: t('metrics.pages.inventory'), disabled: !hasPerm('metricsinventory')},
      {key: 'inventorization', label: t('metrics.pages.inventorization'), disabled: true},
      {key: 'bonuses', label: t('metrics.pages.bonuses'), disabled: !hasPerm('metricsbonus')},
      // {key: 'website', label: t('metrics.pages.website')},
    ],
    onClick: (config: TabPanelConfig) => !config.disabled && navigate(`/reports/${config.key}`, {relative: 'path'}),
    activeTab: activeRoute,
    style: {padding: '0 16px', paddingTop: 8},
  }

  useEffect(() => {
    const sub = authService.profile$.subscribe(setProfile)
    return () => sub.unsubscribe()
  }, [])

  return <>
    {profile && <HeaderContent><TabPanel {...tabsConfig} /></HeaderContent>}
    <Outlet />
  </>
}
