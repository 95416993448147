import {useEffect, useState} from 'react'
import {ManagerConstantModel} from 'src/models/manager/constants'
import appService from 'src/services/app.service'

export function getPipe<T extends {id: number | string}>(
  obj: number | string,
  constants: T[],
  return_val: (val: T) => any,
): any {
  const item = constants.find(val => val.id === obj)
  if (!item) {
    return undefined
  }
  return return_val(item)
}

export function PipeAsync<T>(config: {
  pipe: keyof ManagerConstantModel
  value: number | string
  returnLabel: (val: T) => any
}) {
  const [items, setItems] = useState()
  const [value, setValue] = useState()

  useEffect(() => {
    appService.getConstant(config.pipe).subscribe(items => {
      setItems(items)
      setValue(items.find(val => val.id === config.value))
    })
  }, [config.pipe])

  return config.returnLabel(value)
}
