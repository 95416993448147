import {useTranslation} from "react-i18next"
import {Outlet, useNavigate} from 'react-router-dom'
import Segmented from 'src/components/shared/segmented'
import {useCurrentRoute} from 'src/hooks/useCurrentRoute'

export default function ReportSellsLayout() {
    const navigate = useNavigate()
    const page = useCurrentRoute()
    const {t} = useTranslation()

    return (
        <>
            <Segmented
                onChange={option => navigate(option)}
                value={page}
                segmentStyle={{width: 'max-content', backgroundColor: 'var(--color-gray-100)', marginBottom: 16}}
                options={[
                    {label: t('metrics.finance.pages.main'), value: 'main'},
                    {label: t('metrics.finance.pages.penalties'), value: 'penalties'},
                ]}
            />

            <Outlet />
        </>
    )
}