import {SyntheticEvent} from "react"
import {useTranslation} from "react-i18next"
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom"
import HeaderContent from "src/components/header/header-content"
import Icon from "src/components/shared/components/material-icon"
import Segmented from "src/components/shared/segmented"
import {InventoryGroupModel} from "src/models/manager/inventory/inventory-group.model"


export default function SaleWrapperComponent() {
  const {t} = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const onSale = (e: SyntheticEvent, group: InventoryGroupModel) => {
    e.stopPropagation()
    navigate('/sales/add', {state: {group}})
  }

  return <>
    <HeaderContent>
      <div className="flex items-center justify-between gap-2 header-content">
        <div className="text-2xl font-semibold">{t('sale.header')}</div>
        <Link to="/groups/add" className="btn btn-primary btn-color-white justify-start gap-2 font-semibold text-nowrap">
          <Icon icon="add" />
          {t('groups.add')}
        </Link>
      </div>
    </HeaderContent>

    <Outlet context={{
      title: (
        <Segmented
          onChange={option => navigate(option)}
          value={location.pathname}
          optionStyle={() => ({padding: '8px 24px'})}
          options={[
            {label: t('sale.sections.all'), value: '/sales'},
            {label: t('sale.sections.history'), value: '/sales/history'}
          ]}
        />
      ),
      action: (group: InventoryGroupModel) => (
        <button className='btn btn-card gap-2 w-full' onClick={(e) => onSale(e, group)}>
          <Icon icon="shopping_cart" />
          <span>{t('sale.btn')}</span>
        </button>
      )
    }} />
  </>
}
