import {useTranslation} from "react-i18next"
import {Outlet, createSearchParams, useNavigate} from 'react-router-dom'
import Segmented from 'src/components/shared/segmented'
import {useCurrentRoute} from 'src/hooks/useCurrentRoute'
import useQueryParams from "src/hooks/useQuertParams"

export default function ReportManagerLayout() {
    const {searchParams} = useQueryParams()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const page = useCurrentRoute()

    return (
        <Outlet
            context={{
                header: <Segmented
                    onChange={option => navigate({pathname: option, search: `?${createSearchParams(searchParams)}`})}
                    value={page}
                    segmentStyle={{marginBottom: 16, backgroundColor: 'var(--color-gray-100)'}}
                    options={[
                        {label: t('metrics.managers.pages.salary'), value: 'salary'},
                        {label: t('metrics.managers.pages.workshifts'), value: 'workshifts'},
                    ]}
                />
            }}
        />
    )
}