import {useTranslation} from "react-i18next"
import {useEffect, useState} from "react"
import {Segmented} from "src/components/shared/antd-custom"
import Icon from "src/components/shared/components/material-icon"
import tenantService, {Subscription, SubscriptionPeriod, TenantModel} from "src/services/tenant.service"
import moment from "moment"
import "./styles.scss"

type SubscriptionType = 'free' | 'lite' | 'pro'

function Permission({permitted, children}: {permitted: boolean, children: JSX.Element | string}) {
    return <div className="flex gap-1 items-center text-[15px]">
        {permitted ? <Icon icon="check" className="color-green" /> : <Icon icon="close" className="color-red" />}
        {children}
    </div>
}
const currencyFormatter: Record<'USD' | 'KZT', string> = {
    'USD': "$",
    'KZT': '₸'
}

function TenantSubscriptionTarif({
    subscription,
    current,
    currency,
    option,
    setOption
}: {
    subscription: Subscription,
    current: boolean,
    currency: 'USD' | 'KZT',
    option,
    setOption
}) {
    const options = subscription.periods.filter(period => period.currency === currency).map((period) => {
        const label = moment.duration(period.months, 'months').add(period.days, 'days').humanize()
        return {label, className: 'w-full py-1', value: period.months}
    })

    const tarif: Record<number, SubscriptionPeriod> = Object.fromEntries(subscription.periods.filter(period => period.currency === currency)
        .map((period) => [period.months, period]))

    const limit = subscription.limits

    return (
        <div className="subscription">
            <div className="flex gap-2 flex-col">
                <div className="text-base color-gray-400">{subscription.name}</div>
                <div className="text-2xl font-bold color-primary gap-2">
                    <span style={{fontSize: 32}}>{currencyFormatter[currency]} {tarif[option] ? Number(tarif[option].price / tarif[option].months).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : 0}</span>
                    <span className="font-medium text-2xl"> / в месяц</span>
                </div>
            </div>

            {options.length > 0 && (
                current ? (
                    <button className="btn btn-primary-20 btn-color-primary" style={{height: 44}}>
                        Текущий план
                    </button>
                ) : (
                    <Segmented<number>
                        value={option}
                        onChange={setOption}
                        style={{background: 'var(--color-bg-light)', padding: 4, borderRadius: 6}}
                        options={options}
                    />
                )
            )}

            {limit && <div className="flex flex-col gap-2 flex-1">
                <Permission permitted={true}>
                    <span><span className="font-semibold">{limit.rental_point} пункта</span> проката</span>
                </Permission>
                <Permission permitted={true}>
                    <span><span className="font-semibold">{limit.staff} аккаунт</span> для сотрудника</span>
                </Permission>
                <Permission permitted={true}>
                    {limit.inventory_group === 'unlimited' ? (
                        <span>Кол-во продуктов <span className="font-semibold">без лимита</span></span>
                    ) : (
                        <span><span className="font-semibold">{limit.inventory_group} позиций</span> продуктов</span>
                    )}
                </Permission>
                <Permission permitted={true}>
                    {limit.request === 'unlimited' ? (
                        <span>Кол-во аренд <span className="font-semibold">без лимита</span></span>
                    ) : (
                        <span><span className="font-semibold">{limit.request} аренд</span> в месяц</span>
                    )}
                </Permission>
                <Permission permitted={limit.sale} children="Учет аренд и продуктов" />
                <Permission permitted={limit.sale} children="Учет продаж" />
                <Permission permitted={limit.document} children="Документооборот" />
                <Permission permitted={limit.metrics} children="Аналитика" />
                <Permission permitted={limit.bonus} children="Система лояльности" />
                <Permission permitted={limit.custom_fields} children="Кастомизируемые данные" />
            </div>}
            {current ? (
                <button className="btn btn-card">
                    Оставить подписку
                </button>
            ) : (
                <button className="btn btn-primary btn-color-white">
                    Использовать подписку
                </button>
            )}
        </div>
    )
}


export function TenantSubscriptionModal() {
    const [tenant, setTenant] = useState<TenantModel>()
    const [option, setOption] = useState<number>(6)
    const [subs, setSubs] = useState<Subscription[]>([])
    const [currency, setCurrency] = useState<'USD' | 'KZT'>('KZT')

    useEffect(() => {
        const sub = tenantService.subscriptions$.subscribe(setSubs)
        return () => sub.unsubscribe()
    }, [])

    useEffect(() => {
        const sub = tenantService.tenant$.subscribe(setTenant)
        return () => sub.unsubscribe()
    }, [])

    return tenant && (
        <div className="flex flex-col w-full">
            <div className="subscription-header">
                <div className="flex gap-2 justify-between items-start mb-3">
                    <h3 className="text-3xl font-bold mb-0">Выберите план подписки</h3>
                    <Segmented<'USD' | 'KZT'>
                        value={currency}
                        onChange={setCurrency}
                        style={{background: 'var(--color-bg-light)', padding: 4, borderRadius: 6}}
                        options={[
                            {label: 'USD', value: 'USD'},
                            {label: 'KZT', value: 'KZT'}
                        ]}
                    />
                </div>
                <div className="text-[15px]">В настоящее время вы используете бесплатный план подписки. Выберите план подписки ниже.</div>
            </div>
            <div className="subscription-wrapper">
                {subs.map(sub => (
                    <TenantSubscriptionTarif
                        key={sub.id}
                        subscription={sub}
                        current={tenant.subscription ? +sub.id !== +tenant.subscription.id : false}
                        option={option}
                        setOption={setOption}
                        currency={currency}
                    />
                ))}
            </div>
        </div>
    )
}
