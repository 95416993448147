import {InventoryGroupModel} from 'src/models/manager/inventory/inventory-group.model'
import {FC} from 'react'
import {useTranslation} from "react-i18next"
import SharedInventoryGroupListComponent from "src/components/shared/components/group"

interface Props {
  onPost?: (group: InventoryGroupModel, count: number) => Promise<number[]>
}

export const InventoryGroupModal: FC<Props> = ({onPost}) => {
  const {t} = useTranslation()
  
  return (
    <>
      <div className="text-xl font-bold mb-3">{t('groups.header_modal')}</div>
      <SharedInventoryGroupListComponent params={{type: 0}} addDisabled={false} onSelect={onPost} />
    </>
  )
}
