import {useTranslation} from "react-i18next"
import {CustomFieldChoiceList} from "src/pages/settings/custom-field/field-choice"
import {FormControlMask} from 'src/components/shared/inputs/form-control-mask'
import {FormControl} from 'src/components/shared/inputs/form-control'

export default function ClientLegalPassportComponent() {
  const {t} = useTranslation()
  return (
    <>
      <div className="flex gap-2 mb-3 flex-col md:flex-row">
        <FormControlMask
          name="passport__legal.bin"
          rootclassname="col"
          className="form-control"
          label={t('clients.form.document.legal.bin')}
          placeholder="БИН"
          mask="999999999999"
        />
        <FormControl
          name="passport__legal.address"
          rootclassname="col"
          className="form-control"
          label={t('clients.form.document.legal.address')}
          placeholder={t('clients.form.document.legal.placeholder.address')}
        />
        <FormControl
          name="passport__legal.director"
          rootclassname="col"
          className="form-control"
          label={t('clients.form.document.legal.director')}
          placeholder={t('clients.form.document.legal.placeholder.director')}
        />
      </div>
      <div className="flex gap-2 mb-3 flex-col md:flex-row">
        <FormControl
          name="passport__legal.iban"
          rootclassname="col"
          className="form-control"
          label={t('clients.form.document.legal.iban')}
          placeholder={t('clients.form.document.legal.placeholder.iban')}
        />
        <FormControl
          name="passport__legal.bank"
          rootclassname="col"
          className="form-control"
          label={t('clients.form.document.legal.bank')}
          placeholder={t('clients.form.document.legal.placeholder.bank')}
        />
        <FormControl
          name="passport__legal.bik"
          rootclassname="col"
          className="form-control"
          label={t('clients.form.document.legal.bik')}
          placeholder={t('clients.form.document.legal.bik')}
        />
      </div>
      <CustomFieldChoiceList className="mb-3" prefix="passport__legal." type="client_document" />
    </>
  )
}
