import {useTranslation} from "react-i18next"
import FormSelectAsyncPagination, {FormSelectAsyncConfig} from "../../inputs/form-select-async.pagination"
import {ProfileModel} from "src/services/auth.service"
import usersService from "src/services/users"

type RemovalKeys = 'listOptions' | 'getValue' | 'getOptionLabel' | 'getOptionValue'

export function SharedProfileSelect(config: Omit<FormSelectAsyncConfig<ProfileModel>, RemovalKeys> & {listParams?: any}) {
    const {t} = useTranslation()
    return <FormSelectAsyncPagination<ProfileModel>
        placeholder={t('common.select.placeholder.group')}
        {...config}
        getValue={id => usersService.get(+id)}
        listOptions={params => usersService.list({...params, ...config.listParams})}
        getOptionLabel={(option: ProfileModel) => option ? `${option.first_name} ${option.last_name}` : null}
        getOptionValue={(option: ProfileModel) => option ? String(option.id) : null}
    />
}