import axios from 'axios'
import {BehaviorSubject, Observable, from, shareReplay, switchMap} from 'rxjs'
import {axiosResponce} from '../interceptor/axios-response'

export interface TenantLimitModel {
  name: string
  rental_point: number | "unlimited"
  staff: number | "unlimited"
  discount: boolean
  metrics: boolean
  inventory_set: boolean
  sale: boolean
  service: boolean
  inventorization: boolean
  custom_fields: boolean
  document: boolean
  bonus: boolean
}

export interface TenantModel {
  name: string,
  address: string,
  logo: string,
  start_at: string,
  end_at: string,
  user: number
  created_at: string
  subscription: {
    id: number
    name: 'free' | 'lite' | 'pro'
    extra: string
    limit: TenantLimitModel
  } | undefined
  limits: TenantLimitModel
}

export interface SubscriptionPeriod {
  subscription: number
  price: number
  months: number
  days: number
  currency: string
}

export interface Subscription {
  id: number
  name: string
  order: number
  extra: any
  periods: SubscriptionPeriod[]
  limits: {
    price: number
    rental_point: number
    staff: number
    sale: boolean
    request: false | number | 'unlimited'
    inventory_group: false | number | 'unlimited'
    inventory_set: false | number | 'unlimited'
    metrics: boolean
    custom_fields: boolean
    document: boolean
    bonus: boolean
  }
}

class TenantService {
  refreshing: boolean = false

  getTenant$ = new BehaviorSubject<void>(null)
  getTenant = () => this.getTenant$.next()

  listSubscriptions$ = new BehaviorSubject<void>(null)
  listSubscriptions = () => this.listSubscriptions$.next()

  tenant$: Observable<TenantModel> = this.getTenant$.pipe(
    switchMap(() => from(axios.get<TenantModel>('/v1/tenant/account/')).pipe(axiosResponce)),
    shareReplay(1)
  )

  subscriptions$: Observable<Subscription[]> = this.getTenant$.pipe(
    switchMap(() => from(axios.get<Subscription[]>('/v1/tenant/subscriptions/')).pipe(axiosResponce)),
    shareReplay(1)
  )
}

const tenantService = new TenantService()
export default tenantService
