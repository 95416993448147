import FormSelectAsync, {FormSelectAsyncConfig} from "../../inputs/form-select-async.list"
import {Observable, firstValueFrom, map} from "rxjs"
import appService from "src/services/app.service"
import {InventoryTarifTimePeriod} from "src/models/manager/constants"
import {useTranslation} from "react-i18next"
import secondsToHuman from "src/utils/secondsToHuman"


type RemovalKeys = 'listOptions' | 'getValue' | 'getOptionLabel' | 'getOptionValue' | 'placeholder'
const timePeriod: Observable<InventoryTarifTimePeriod[]> = appService.getConstant('TARIF_TIME_PERIODS')

export function SharedTimePeriodSelect(config: Omit<FormSelectAsyncConfig<InventoryTarifTimePeriod>, RemovalKeys>) {
    const {t} = useTranslation()
    return <FormSelectAsync<InventoryTarifTimePeriod>
        {...config}
        listOptions={() => timePeriod}
        placeholder={t('common.select.placeholder.period')}
        getValue={(id: number) => firstValueFrom(timePeriod.pipe(map(list => list.find(obj => obj.id === +id))))}
        getOptionLabel={(option: InventoryTarifTimePeriod) => (option ? `${option.name} (${secondsToHuman(option.time)})` : null)}
        getOptionValue={(option: InventoryTarifTimePeriod) => (option ? String(option.id) : null)}
    />
}