import printJS from 'print-js'
import {SyntheticEvent} from 'react'
import {GroupBase, OptionProps, components} from 'react-select'
import {FormProvider, useForm} from "react-hook-form"
import FormSelectAsync from "src/components/shared/inputs/form-select-async.list"
import {DocumentModel} from "src/models/manager/document"
import {OrderRequestListModel} from 'src/models/manager/order/order-request-list.model'
import documentTemplateService from "src/services/document-template.service"
import {getPrintStyles} from "../../config/documents/config"
import {colourStyles} from "src/components/shared/inputs/form-select-color-style"
import Icon from "src/components/shared/components/material-icon"
import {useTranslation} from "react-i18next"
import pdfService from "src/services/pdf.service"
import orderDocumentService from "src/services/order-requests/order-document.service"
import modalService from "src/components/modal/global/modal.service"
import {Link} from "react-router-dom"
import {OrderRequestDocumentModel} from "src/models/manager/order/order-request-document"

function DocumentSelectOption(props: OptionProps<DocumentModel, boolean, GroupBase<DocumentModel>> & {order: OrderRequestListModel}) {
    const onWebPrint = (doc: OrderRequestDocumentModel) => {
        const htmlContent = `<div class="ck-content">${doc.content}</div>`
        document.title = doc.name

        printJS({
            printable: htmlContent,
            type: 'raw-html',
            header: doc.name,
            style: getPrintStyles(),
            showModal: true,
            repeatTableHeader: false,
            modalMessage: 'Документ формируется',
            onError: (e) => console.log(e)
        })
    }

    const onPrint = (event: SyntheticEvent, template: DocumentModel) => {
        event.stopPropagation()
        orderDocumentService.post(props.order.id, {template: template.id, context: {}}).then(onWebPrint)
    }

    const onDownload = (event: SyntheticEvent, template: DocumentModel) => {
        event.stopPropagation()
        orderDocumentService.post(props.order.id, {template: template.id, context: {}}).then((doc) => {
            pdfService.generate(doc.name, doc.content)
        })
    }

    const onView = async (event: SyntheticEvent, template: DocumentModel) => {
        event.stopPropagation()
        orderDocumentService.post(props.order.id, {template: template.id, context: {}}).then((doc) => {
            modalService.open({
                props: {size: 'lg', scrollable: true, dialogClassName: "ck-content-modal"},
                component: (
                    <div className="flex gap-3 flex-col justify-center">
                        <div className="flex gap-2 items-center justify-center">
                            <span className="text-xl font-bold">{doc.name}</span>
                            <Link to={`/documents/${template.id}/entity/${doc.id}`} className="btn btn-card">
                                <Icon icon="edit" />
                            </Link>
                            <button onClick={() => onWebPrint(doc)} className="btn btn-card">
                                <Icon icon="print" />
                            </button>
                            <button onClick={() => pdfService.generate(doc.name, doc.content)} className="btn btn-card">
                                <Icon icon="download" />
                            </button>
                        </div>
                        <div
                            className="ck-view mx-auto overflow-auto"
                            style={{maxWidth: '100%'}}
                            dangerouslySetInnerHTML={{__html: doc.content}}
                        />
                    </div>
                )
            })
        })
    }

    const config = {
        ...props,
        innerProps: {...props.innerProps, onClick: (e: SyntheticEvent) => onPrint(e, props.data)}
    }

    return (
        <components.Option {...config}>
            <div className="flex items-center gap-2">
                <Icon className="text-[21px]" icon="description" />
                <span className="flex-1">{props.data.name}</span>
                <div className="flex gap-2">
                    <button onClick={(e) => onDownload(e, props.data)} className="btn btn-card">
                        <Icon className="text-base" icon="download" />
                    </button>
                    <button onClick={(e) => onView(e, props.data)} className="btn btn-card">
                        <Icon className="text-base" icon="visibility" />
                    </button>
                </div>
            </div>
        </components.Option>
    )
}

export function OrderCardDocument({order, view = 'grid'}: {order: OrderRequestListModel, view?: 'table' | 'grid'}) {
    const form = useForm<{document: number}>()
    const {t} = useTranslation()

    return (
        <FormProvider {...form}>
            <div className={view === "grid" ? "order-card-content" : ""} onClick={(e) => e.stopPropagation()}>
                <FormSelectAsync<DocumentModel>
                    className="col"
                    placeholder={view === "grid" ? t('orders.list.document') : <Icon className="text-lg" icon="description" />}
                    name="discount"
                    listOptions={() => documentTemplateService.documents$}
                    getOptionLabel={(option: DocumentModel) => option ? option.name : null}
                    getOptionValue={(option: DocumentModel) => (option ? String(option.id) : null)}
                    menuPortalTarget={document.getElementById('vhContent')}
                    styles={view === "grid" ? colourStyles(false) : {
                        ...colourStyles(false),
                        menu: (...args) => ({
                            ...colourStyles(false).menu(...args),
                            minWidth: 320,
                            right: 0
                        }),
                        control: (...args) => ({
                            ...colourStyles(false).control(...args),
                            width: 48,
                            height: 48,
                            border: '1px solid var(--color-gray-100)',
                            borderRadius: 8,
                            color: 'var(--color-black)',
                            backgroundColor: 'var(--color-white)',
                        }),
                        valueContainer: (...args) => ({
                            ...colourStyles(false).valueContainer(...args),
                            padding: 0,
                            paddingLeft: 0,
                            rowGap: 0,
                            columnGap: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center'
                        }),
                        placeholder: (...args) => ({
                            ...colourStyles(false).placeholder(...args),
                            color: 'var(--color-black)',
                            lineHeight: 1
                        }),
                        indicatorSeparator: () => ({display: 'none'}),
                        indicatorsContainer: () => ({display: 'none'}),
                    }}
                    components={{Option: (props: OptionProps<DocumentModel, boolean, GroupBase<DocumentModel>>) => DocumentSelectOption({...props, order})}}
                    isSearchable={false}
                    isClearable={false}
                    menuPlacement="top"
                />
            </div>
        </FormProvider >
    )
}