import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface LoadingStore {
  loading: boolean
}

const initialState: LoadingStore = {
  loading: false,
}

const slice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setLoading: (state, {payload: loading}: PayloadAction<boolean>) => {
      state.loading = loading
    },
  },
})

export const {setLoading} = slice.actions

export default slice.reducer
