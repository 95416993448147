import {useEffect} from 'react'
import {useFormContext} from 'react-hook-form'
import {useDebounce} from 'src/hooks/useDebounce'
import {LabelModel} from 'src/models/label'
import {AsyncRentalPoint} from "src/abstract/async-rental-point"
import FormSelectAsync from "src/components/shared/inputs/form-select-async.list"
import appService from "src/services/app.service"
import {firstValueFrom, map} from "rxjs"
import {FormControl} from "src/components/shared/inputs/form-control"
import {CustomFieldFilterList} from "../../settings/custom-field/field-filter"
import {useTranslation} from "react-i18next"

export default function InventoryListFilter({customFieldFilter = {}}: {customFieldFilter?: any}) {
    const form = useFormContext<any>()
    const {reset, watch} = form
    const {t} = useTranslation()
    const values = watch()
    const searchDebounce = useDebounce(watch('search'), 500)

    useEffect(() => reset({...values, search: searchDebounce, page: 1}), [searchDebounce])

    return <>
        <div className="flex justify-between gap-3 mb-3">
            <AsyncRentalPoint name='rental_point' />
            <div className="col">
                <FormSelectAsync<LabelModel>
                    name="status"
                    className='w-full'
                    placeholder={t('orders.inventory.filter')}
                    listOptions={() => appService.getConstant('INVENTORY_STATUS')}
                    getValue={id => firstValueFrom(appService.getConstant('INVENTORY_STATUS').pipe(map(list => list.find(obj => obj.id === +id))))}
                    getOptionLabel={(option: LabelModel) => (option ? option.label : undefined)}
                    getOptionValue={(option: LabelModel) => (option ? String(option.id) : undefined)}
                    isClearable={true}
                    isSearchable={false}
                />
            </div>
            <div className="col"></div>
            <FormControl
                rootclassname="col"
                icon="search"
                name="search"
                className="form-control"
                placeholder={t('common.input.search')}
            />
        </div>

        <CustomFieldFilterList className="mb-3" prefix="extra__" type="inventory" filter={customFieldFilter} />
    </>
}
