import FormSelectAsync, {FormSelectAsyncConfig} from "../../inputs/form-select-async.list"
import {Observable, firstValueFrom, map} from "rxjs"
import appService from "src/services/app.service"
import {LabelModel} from "src/models/label"

type RemovalKeys = 'listOptions' | 'getValue' | 'getOptionLabel' | 'getOptionValue'
const clientTypes: Observable<LabelModel[]> = appService.getConstant('CLIENT_TYPE')

export function SharedClientTypeSelect(config: Omit<FormSelectAsyncConfig<LabelModel>, RemovalKeys>) {
    return <FormSelectAsync<LabelModel>
        {...config}
        listOptions={() => clientTypes}
        getValue={(id: number) => firstValueFrom(clientTypes.pipe(map(list => list.find((obj: LabelModel) => obj.id === id))))}
        getOptionLabel={(option: LabelModel) => (option ? String(option.label) : null)}
        getOptionValue={(option: LabelModel) => (option ? String(option.id) : null)}
    />
}