import moment from 'moment'

export default function secondsToHuman(timeInSeconds: number | string) {
  if (!timeInSeconds) return ''
  const duration = moment.duration(timeInSeconds, 'seconds')
  const parts = []
  const days = duration.days()
  if (days) parts.push(`${moment.duration(days, 'days').days()} д`)

  const hours = duration.hours()
  if (hours) parts.push(`${moment.duration(hours, 'hours').hours()} ч`)

  const minutes = duration.minutes()
  if (minutes) parts.push(`${moment.duration(minutes, 'minutes').minutes()} м`)

  return parts.join(' ')
}
