import {useContext, useMemo} from 'react'
import {Link} from 'react-router-dom'
import {ORDER_STATUS_NUMBER_CODE, ORDER_STATUS_NUMBER_NAME} from "src/components/shared/constants"
import {invertColor} from "src/utils/color/color-invert"
import Icon from "src/components/shared/components/material-icon"
import {useTranslation} from "react-i18next"
import {ConfigContext} from "src"

export function RequestStatus({requestId, requestStatus}: {requestId: number, requestStatus: number}) {
    const {t} = useTranslation()
    const {settings} = useContext(ConfigContext)
    const color = useMemo(() => settings[ORDER_STATUS_NUMBER_CODE[requestStatus]], [requestId, requestStatus])

    return requestId && requestStatus && (
        <div className="flex w-full">
            <div
                className="flex items-center justify-center px-2 w-full"
                style={{backgroundColor: color, color: invertColor(color), borderTopLeftRadius: 8, borderBottomLeftRadius: 8}}
            >
                <span className="font-medium">{t(ORDER_STATUS_NUMBER_NAME[requestStatus])}</span>
            </div>
            <Link to={`/orders/${requestId}/all`} className="btn btn-gray-100" style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}>
                <Icon icon="chevron_right" />
            </Link>
        </div>
    )
}