import ProfileMetricsComponent from './profile'
import {ProfileModel} from 'src/services/auth.service'
import {useOutletContext} from "react-router"
import {useTranslation} from "react-i18next"

export default function ProfileActivityComponent() {
    const {t} = useTranslation()
    const {profile} = useOutletContext<{profile: ProfileModel}>()
    return profile && <div className="w-full" style={{padding: '32px 24px'}}>
        <div className="text-3xl font-bold mb-3">{t('profile.activity')}</div>
        <ProfileMetricsComponent userId={profile.id} />
    </div>
}
