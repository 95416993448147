import moment from 'moment'
export const startOfMonth = moment().startOf('month')
export const endOfMonth = moment().endOf('month').add(1, 'days')

// inventory statuses
export const INVENTORY_FREE = 0
export const INVENTORY_RESERVED = 1
export const INVENTORY_INREND = 2
export const INVENTORY_BROKEN = 3
export const INVENTORY_OVERDUE = 4
export const INVENTORY_DISABLED = 5
export const INVENTORY_SOLD = 6

export type INVENTORY_STATUS =
  typeof INVENTORY_FREE |
  typeof INVENTORY_RESERVED |
  typeof INVENTORY_INREND |
  typeof INVENTORY_BROKEN |
  typeof INVENTORY_OVERDUE |
  typeof INVENTORY_DISABLED |
  typeof INVENTORY_SOLD

// order status
export type ORDER_STATUS = 'request' | 'reserve' | 'inrent' | 'cancel' | 'completed' | 'debtor' | 'exceed'

export const ORDER_STATUS_NAME: Record<ORDER_STATUS, string> = {
  request: 'common.order_status.request',
  reserve: 'common.order_status.reserve',
  inrent: 'common.order_status.inrent',
  cancel: 'common.order_status.cancel',
  completed: 'common.order_status.completed',
  debtor: 'common.order_status.debtor',
  exceed: 'common.order_status.exceed',
}

export const ORDER_STATUS_NUMBER_NAME: Record<number, string> = {
  0: 'common.order_status.request',
  1: 'common.order_status.reserve',
  2: 'common.order_status.inrent',
  3: 'common.order_status.cancel',
  4: 'common.order_status.completed',
  5: 'common.order_status.debtor',
}

export const ORDER_STATUS_NUMBER_CODE: Record<number, ORDER_STATUS> = {
  0: 'request',
  1: 'reserve',
  2: 'inrent',
  3: 'cancel',
  4: 'completed',
  5: 'debtor'
}

export const PAYMENT_STATUS_NAME: Record<number, string> = {
  0: 'common.payment_status.not_paid',
  1: 'common.payment_status.paid',
  2: 'common.payment_status.partly_paid'
}

export const Validators = {
  required: {required: true},
  percent: {valueAsNumber: true, validate: value => value > 0 && value <= 100},
}

export const colorPaletes: Record<INVENTORY_STATUS, string> = {
  [INVENTORY_FREE]: '#0BD360',
  [INVENTORY_RESERVED]: '#B77EFF',
  [INVENTORY_INREND]: '#B77EFF',
  [INVENTORY_BROKEN]: '#EC1D1D',
  [INVENTORY_OVERDUE]: '#B77EFF',
  [INVENTORY_DISABLED]: '#ECECECE',
  [INVENTORY_SOLD]: '#EC1D1D'
}

