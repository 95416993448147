import {createContext, useEffect, useState} from 'react'
import {useFieldArray, useForm} from 'react-hook-form'
import {useParams} from 'react-router'
import {DetailOrderRequestModel} from 'src/models/manager/order/order-request-detail.model'
import {OrderInventoryUnavailable} from 'src/models/manager/order/order-request-inventory-unavailable.model'
import inventoryService from 'src/services/inventory/inventory.service'
import orderInventoryService from 'src/services/order-requests/order-inventory.service'
import orderRequestServiceService from 'src/services/order-requests/order-service.service'
import orderService from 'src/services/order-requests/order.service'
import {getMinMaxDate} from 'src/utils/min-max-date'
import {OrderContextType, OrderForm} from './order-context.model'
import {firstValueFrom} from "rxjs"
import {OrderRequestInventoryModel} from "src/models/manager/order/order-request-inventory"
import {isNil} from "src/utils/isNil"
import {InventoryModel} from "src/models/manager/inventory/inventory.model"
import modalService from "src/components/modal/global/modal.service"
import detectServiceChange from "../change-detection/service"
import checkTimeRange from "../unavailability-check"
import {detectChanges} from "../change-detection/inventory"
import OrderDetailInventoryListComponent from "../inventories/inventory-list/inventory-add"

export const OrderContext = createContext<OrderContextType>(undefined)

export const OrderDetailProvider = ({children}: {children: JSX.Element}) => {
  const {id} = useParams<{id: string}>()
  const [order, setOrder] = useState<DetailOrderRequestModel>()
  const [inventories, setInventories] = useState<OrderRequestInventoryModel[]>([])
  const [checkouts, setCheckouts] = useState<number[]>([])
  const [backCheckouts, setBackCheckouts] = useState<number[]>([])
  const [prematureStart, setPrematureStart] = useState<OrderInventoryUnavailable[]>([])
  const [flexOrder, setFlexOrder] = useState<boolean>(false)
  const orderForm = useForm<OrderForm>()
  const formValue = orderForm.watch()

  const servicesForm = useFieldArray({
    control: orderForm.control,
    name: 'services',
  })

  const createServicesForm = useFieldArray({
    control: orderForm.control,
    name: 'create_services',
  })

  const addCheckout = (id: number) => {
    if (backCheckouts.length > 0) return
    if (checkouts.includes(id)) {
      setCheckouts(checkouts.filter(i => i !== id))
    } else {
      setCheckouts([...checkouts, id])
    }
  }

  const addCheckouts = (ids: number[]) => {
    if (backCheckouts.length > 0) return
    if (checkouts.some(id => ids.includes(id))) {
      setCheckouts(checkouts.filter(i => !ids.includes(i)))
    } else {
      setCheckouts([...checkouts, ...ids])
    }
  }

  const addBackCheckout = (id: number) => {
    if (checkouts.length > 0) return
    if (backCheckouts.includes(id)) {
      setBackCheckouts(backCheckouts.filter(i => i !== id))
    } else {
      setBackCheckouts([...backCheckouts, id])
    }
  }

  const addBackCheckouts = (ids: number[]) => {
    if (checkouts.length > 0) return
    if (backCheckouts.some(id => ids.includes(id))) {
      setBackCheckouts(backCheckouts.filter(i => !ids.includes(i)))
    } else {
      setBackCheckouts([...backCheckouts, ...ids])
    }
  }

  const onExchange = (key: string) => {
    const formValue: Partial<OrderRequestInventoryModel> = orderForm.watch(`inventoryMap.${key}`)
    const ids: number[] = Object.keys(orderForm.watch('inventoryMap')).map(key => orderForm.watch(`inventoryMap.${key}.inventory.id`)) || []
    const onSelect = (inventory: InventoryModel) => {
      orderForm.setValue(`inventoryMap.${key}`, {...formValue, inventory, unavailables: []})
      modalService.closeModal()
    }
    const params = {
      group: formValue.inventory.group,
      start_at: orderForm.watch('rent_start'),
      end_at: orderForm.watch('rent_end'),
      exclude_ids: ids.join(',')
    }
    modalService.open({
      props: {size: 'xl'},
      component: <OrderDetailInventoryListComponent filter={params} onSelect={onSelect} />
    })
  }

  const updateForm = (_order: DetailOrderRequestModel, _inventories: OrderRequestInventoryModel[]) => {
    const setInventories = _inventories.filter(inventory => !!inventory.set)
    let groupedSets = {}

    for (let i = 0, len = setInventories.length, p; i < len; i++) {
      p = setInventories[i]
      if (groupedSets[p.set] === undefined) groupedSets[p.set] = {}
      if (groupedSets[p.set][p.set_group] === undefined) groupedSets[p.set][p.set_group] = []
      groupedSets[p.set][p.set_group].push(p)
    }

    orderForm.reset({
      rental_point: _order.rental_point,
      client: _order.client,
      rent_start: _order.rent_start,
      rent_fact_start: _order.rent_fact_start,
      rent_end: _order.rent_end,
      rent_fact_end: _order.rent_fact_end,
      discount: _order.discount,
      additional_discount: _order.additional_discount,
      penalty_disabled: _order.penalty_disabled,
      penalty_step_duriation: _order.penalty_step_duriation,
      default_tarif_period: _order.default_tarif_period,
      extra: _order.extra,
      setMap: _order.setMap,
      groupMap: _order.groupMap,
      inventoryMap: _order.inventoryMap,
      inventoryGroupMap: _order.inventoryGroupMap,
      inventorySetMap: _order.inventorySetMap,
      services: _order.services.map(obj => ({
        object_id: obj.id,
        service: obj.service,
        price: obj.price,
        price_discount: obj.price_discount,
        tarif_price: obj.tarif_price,
        start_at: obj.start_at || _order.rent_start,
        end_at: obj.end_at || _order.rent_end,
        worker: obj.worker && obj.worker.id,
        tarif: obj.tarif,
        info: obj.info,
        extra: obj.extra,
      })),
      create_services: [],
    })

    const times = new Set<string>([
      _order.rent_start,
      _order.rent_end,
      ..._inventories.reduce((p, c) => [...p, c.start_at, c.end_at], []),
      ..._order.services.reduce((p, c) => [...p, c.start_at, c.end_at], []),
    ])

    if (times.size === 2) setFlexOrder(false)
    if (times.size > 2) setFlexOrder(true)
  }

  const inventoriesIds: number[] = formValue && formValue.inventoryMap ? Object.keys(formValue.inventoryMap).map(key => formValue.inventoryMap[key].inventory.id) : []

  const updateOrder = async () => {
    setCheckouts([])
    setBackCheckouts([])

    const [_order, _inventories, _groups, _sets] = await Promise.all([
      firstValueFrom(orderService.get(+id)),
      orderInventoryService.listInventories(+id),
      orderInventoryService.listGroups(+id),
      orderInventoryService.listSets(+id)
    ])

    const inventoryMap = Object.fromEntries(_inventories.map((t) => [`${t.id}-${t.inventory.id}`, t]))
    const inventoryGroupMap = Object.fromEntries(_groups.map(g => [g.id, _inventories.filter(inv => inv.inventory.group === g.id && !inv.set).map(obj => `${obj.id}-${obj.inventory.id}`)]))
    const invSet = new Set(_sets.reduce((p, s) => [...p, ..._inventories.filter(i => i.set === s.id).map(i => `${s.id}-${i.set_group}`)], []))
    const inventorySetMap = Object.fromEntries([...invSet.values()].map((key) => {
      const [setId, setGroupKey] = key.split('-')
      return [key, _inventories.filter(i => i.set === +setId && i.set_group === setGroupKey).map(i => `${i.id}-${i.inventory.id}`)]
    }))

    _order.setMap = Object.fromEntries(_sets.map(s => [s.id, s]))
    _order.groupMap = Object.fromEntries(_groups.map(g => [g.id, g]))
    _order.inventoryMap = inventoryMap

    _order.inventorySetMap = inventorySetMap
    _order.inventoryGroupMap = inventoryGroupMap

    const values = [
      _order.rent_start,
      _order.rent_end,
      _order.rent_fact_start,
      _order.rent_fact_end,
      ..._inventories.map(inv => inv.start_at),
      ..._inventories.map(inv_1 => inv_1.end_at),
      ..._inventories.map(inv_2 => inv_2.fact_start_at),
      ..._inventories.map(inv_3 => inv_3.fact_end_at),
    ].filter(time => !!time)

    const [minDate, maxDate] = getMinMaxDate(values)
    _order.minStart = minDate
    _order.maxEnd = maxDate
    setOrder(_order)
    setInventories(_inventories)

    updateForm(_order, _inventories)
    return _order
  }

  const updateInventories = async () => {
    const _inventories = await orderInventoryService.listInventories(+id)
    const inventoryMap = Object.fromEntries(_inventories.map((t) => [`${t.id}-${t.inventory.id}`, t]))
    const keys = Object.keys(inventoryMap)

    const inventoryGroupMap = orderForm.watch('inventoryGroupMap')
    const groupMap = Object.keys(inventoryGroupMap).map(key => ({key, list: inventoryGroupMap[key].filter(str => keys.includes(str))}))

    const inventorySetMap = orderForm.watch('inventorySetMap')
    const setMap = Object.keys(inventorySetMap).map(key => ({key, list: inventorySetMap[key].filter(str => keys.includes(str))}))

    orderForm.setValue('inventorySetMap', Object.fromEntries(setMap.map((obj) => [obj.key, obj.list])))
    orderForm.setValue('inventoryGroupMap', Object.fromEntries(groupMap.map((obj) => [obj.key, obj.list])))
    orderForm.setValue('inventoryMap', inventoryMap)
  }

  const onSubmit = orderForm.handleSubmit(
    async payload => {
      const orderInventories = Object.fromEntries(inventories.map(form => [form.id, form]))
      const inventoryKeys = Object.keys(payload.inventoryMap)

      const creatingInventoryKeys = inventoryKeys.filter(key => Number.isNaN(+key.split('-')[0]) && !isNil(payload.inventoryMap[key]))
      const updatingInventoryKeys = inventoryKeys.filter(key => !Number.isNaN(+key.split('-')[0]) && !isNil(payload.inventoryMap[key]))

      const creatingInventories = creatingInventoryKeys.map(key => {
        const obj = payload.inventoryMap[key]
        return {
          set: obj.set || null,
          set_group: obj.set_group || null,
          inventory: obj.inventory.id,
          tarif: obj.tarif,
          tarif_price: obj.tarif_price,
          tarif_duration: obj.tarif_duration,
          discount: obj.discount,
          additional_discount: obj.additional_discount,
          start_at: obj.start_at,
          end_at: obj.end_at,
          type: obj.type
        }
      })

      const updatingInventories = updatingInventoryKeys.map(key => {
        const obj = payload.inventoryMap[key]
        return {
          id: obj.id,
          set: obj.set,
          set_group: obj.set_group,
          inventory: obj.inventory.id,
          tarif: obj.tarif,
          tarif_price: obj.tarif_price,
          tarif_duration: obj.tarif_duration,
          discount: obj.discount,
          additional_discount: obj.additional_discount,
          start_at: obj.start_at,
          end_at: obj.end_at,
          type: obj.type
        }
      }).filter(obj => {
        const form = orderInventories[obj.id]
        const form_obj = {
          id: form.id,
          set: form.set,
          set_group: form.set_group,
          inventory: form.inventory.id,
          tarif: form.tarif,
          tarif_price: form.tarif_price,
          tarif_duration: form.tarif_duration,
          discount: form.discount,
          additional_discount: form.additional_discount,
          start_at: form.start_at,
          end_at: form.end_at
        }
        return detectChanges(obj, form_obj) === false
      })

      const updateServices = detectServiceChange(order.services, payload.services)

      const createServices = payload.create_services.map(obj => ({
        service: obj.service.id,
        tarif: obj.tarif,
        worker: obj.worker,
        info: obj.info,
        extra: obj.extra,
        start_at: obj.start_at,
        end_at: obj.end_at,
      }))

      const updateServicesRequests = updateServices.map(payload =>
        orderRequestServiceService.patch(order.id, payload.id, payload),
      )

      const createServiceRequests = createServices.map(payload =>
        orderRequestServiceService.post(order.id, payload)
      )

      const orderPayload = {
        client: payload.client,
        rent_start: payload.rent_start,
        rent_end: payload.rent_end,
        default_tarif_period: payload.default_tarif_period || null,
        discount: payload.discount || null,
        additional_discount: payload.additional_discount || 0,
        penalty_disabled: payload.penalty_disabled || false,
        penalty_step_duriation: payload.penalty_step_duriation,
        extra: payload.extra
      }

      const promises: Array<Promise<any>> = [
        ...updateServicesRequests,
        ...createServiceRequests,
      ]

      if (creatingInventories.length > 0) {
        promises.push(orderInventoryService.bulkCreate(order.id, {inventories: creatingInventories}))
      }

      if (updatingInventories.length > 0) {
        promises.push(orderInventoryService.bulkUpdate(order.id, {inventories: updatingInventories}))
      }

      await Promise.all(promises)
      await orderService.patch(order.id, orderPayload)
      await updateOrder()
    },
    (e) => console.log(e)
  )

  const disableSave = orderForm.formState.isValid

  useEffect(() => {
    updateOrder()
    return () => setOrder(null)
  }, [id])

  useEffect(() => {
    const value = orderForm.watch()
    if (value.rent_start && value.rent_end) {
      const ids = Object.keys(value.inventoryMap).map(key => key.split('-')[1])
      const start_at = orderForm.watch('rent_start')
      const end_at = orderForm.watch('rent_end')
      const params = {start_at, end_at, id__in: ids.join(',')}

      inventoryService.listUnavailables(params).then((schedule) => {
        const inventorySchedule = Object.fromEntries(ids.map(id => [id, schedule.filter(s => s.inventory === Number(id))]))

        Object.keys(value.inventoryMap).forEach(key => {
          const id = key.split('-')[1]
          const form = value.inventoryMap[key]
          const unavailables = inventorySchedule[id].filter(val => {
            const _1 = val.order !== form.id
            const _2 = checkTimeRange(val.start_at, val.end_at, form.fact_start_at || form.start_at, form.fact_start_at || form.end_at)
            return _1 && _2
          })
          orderForm.setValue(`inventoryMap.${key}`, {...form, unavailables})
        })
      })
    }
  }, [orderForm.watch('rent_start'), orderForm.watch('rent_end')])

  return (
    <OrderContext.Provider
      value={{
        order,
        inventories,
        disableSave,
        flexOrder,
        setFlexOrder,
        checkouts,
        backCheckouts,
        addCheckout,
        addCheckouts,
        addBackCheckout,
        addBackCheckouts,
        orderForm,
        servicesForm,
        createServicesForm,
        updateOrder,
        updateInventories,
        onSubmit,
        prematureStart,
        setPrematureStart,
        inventoriesIds,
        onExchange
      }}
    >
      {order && children}
    </OrderContext.Provider>
  )
}
