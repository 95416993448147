import authService, {ProfileModel} from 'src/services/auth.service'
import {FormProvider, useForm} from 'react-hook-form'
import payloadFilter from "src/utils/payload.filter"
import UserStaffPasswordModal from "../accounts/user/user-edit-pasword-modal"
import modalService from "src/components/modal/global/modal.service"
import {useOutletContext} from "react-router"
import {FormControl} from 'src/components/shared/inputs/form-control'
import permissionService, {PermissionGroupModel} from 'src/services/permission.service'
import FormSelectAsync from "src/components/shared/inputs/form-select-async.list"
import {ImageInputField} from "src/components/shared/components/image-field"
import {useTranslation} from "react-i18next"
import {SharedRentalPointSelect} from "src/components/shared/components/select/rental-point"
import {from} from "rxjs"

export default function ProfileEditComponent() {
  const {profile} = useOutletContext<{profile: ProfileModel}>()
  const {t} = useTranslation()
  const form = useForm<ProfileModel>({defaultValues: profile})
  const {handleSubmit} = form

  const onPatch = async (payload: FormData) => {
    await authService.updateProfile(payload)
    return authService.getProfile()
  }

  const onSubmit = handleSubmit(data => {
    const formData = new FormData()
    const payload = payloadFilter(data, profile)
    delete payload.user_permissions

    Object.keys(payload).forEach(key => formData.append(key, payload[key]))
    formData.append('is_staff', 'true')
    onPatch(formData)
  })

  const onExitProfile = () => authService.logout()

  const onPasswordChange = () => {
    const onChange = async payload => {
      await authService.updateProfile(payload)
      return authService.getProfile()
    }
    modalService.open({component: <UserStaffPasswordModal onSubmit={onChange} />})
  }

  return <FormProvider {...form}>
    <div className="p-3 w-full">
      <div className="flex flex-col mb-3 ">
        <ImageInputField
          name="avatar"
          style={{objectFit: 'cover', width: 128, height: 128, borderRadius: '50%'}}
        />
      </div>

      <div className="col w-full">
        <div className="flex w-full gap-3 mb-3">
          <FormControl
            required
            label={t('users.form.username')}
            placeholder={t('users.form.placeholder.username')}
            rootclassname="col"
            className="form-control"
            name="username"
            params={{required: true}}
          />
          <FormControl
            required
            label={t('users.form.first_name')}
            placeholder={t('users.form.placeholder.first_name')}
            rootclassname="col"
            className="form-control"
            name="first_name"
            params={{required: true}}
          />
          <FormControl
            required
            label={t('users.form.last_name')}
            placeholder={t('users.form.placeholder.last_name')}
            rootclassname="col"
            className="form-control"
            name="last_name"
            params={{required: true}}
          />
          <FormControl
            label={t('users.form.email')}
            placeholder={t('users.form.placeholder.email')}
            rootclassname="col"
            className="form-control"
            name="email"
            params={{pattern: {value: /\S+@\S+\.\S+/, message: t('users.form.error.email')}}}
          />
        </div>
        <div className="flex w-full gap-3 mb-3">
          <FormControl
            label={t('users.form.salary_amount')}
            placeholder={t('users.form.placeholder.salary_amount')}
            rootclassname="col"
            disabled={true}
            className="form-control"
            name="salary_amount"
          />
          <FormSelectAsync<PermissionGroupModel>
            className="col"
            label={t('common.select.role')}
            placeholder={t('common.select.placeholder.role')}
            name="group"
            disabled={true}
            listOptions={() => from(permissionService.groups({skip_loader: true}))}
            getValue={(id: number) => permissionService.getGroup(id)}
            getOptionLabel={(option: PermissionGroupModel) => (option ? String(option.name) : null)}
            getOptionValue={(option: PermissionGroupModel) => (option ? String(option.id) : null)}
            isClearable={true}
            isSearchable={false}
          />
          <SharedRentalPointSelect
            className="col"
            name="point"
            disabled={true}
            label={t('common.select.rental_point')}
            isClearable={true}
            isSearchable={false}
          />
        </div>
      </div>

      <div className="flex justify-end gap-3 w-full">
        <button className="btn btn-red btn-color-white static-header-btn-padding" onClick={onExitProfile}>
          {t('profile.logout')}
        </button>
        <button className="btn btn-primary btn-color-white" onClick={onPasswordChange}>
          {t('profile.change_password')}
        </button>
        <button onClick={onSubmit} className="btn btn-primary btn-color-white">
          {t('common.save')}
        </button>
      </div>
    </div>
  </FormProvider>
}

