import {ImageField} from "src/components/shared/image-loader/image"
import {IntegrationModel} from "src/models/integration"
import "./styles.scss"
import modalService from "src/components/modal/global/modal.service"
import {useNavigate} from "react-router"
import integrationService from "src/services/integration"

export function IntegrationConnection({integration}: {integration: IntegrationModel}) {
    const navigate = useNavigate()

    const onInstall = async () => {
        await integrationService.connect(integration.code)
        navigate(`/integrations/${integration.code}`)
    }

    const onCancel = () => modalService.closeModal()

    return <div className="">
        <h4 className="text-xl font-bold mb-3">Подключение интеграции</h4>

        <div className="card-bio flex gal-2 justify-between items-center">

            <div className="flex items-center gap-2">

                <div className="integration-list-icon">
                    <ImageField
                        draggable={false}
                        src={integration.icon}
                        style={{objectFit: 'contain', width: 32, height: 32}}
                    />
                </div>

                <div className="flex gap-2 flex-col">
                    <div className="text-lg font-bold">{integration.name}</div>
                    <div className="color-gray-400">{integration.comment}</div>
                </div>

            </div>

            {integration.extra && 'is_yume_cloud' in integration.extra && integration.extra['is_yume_cloud'] && (
                <span className="color-primary fw-semibold text-nowrap">от yume.cloud</span>
            )}
        </div>

        <div className="flex gap-2 justify-end mt-3">
            <button className="btn btn-card" onClick={onCancel}>Отменить</button>
            <button className="btn btn-primary btn-color-white" onClick={onInstall}>Установить</button>
        </div>
    </div>
}