export interface ArticleModel {
    id: number
    title: string
    body: string
    outer_url: string
    active: boolean
    extra: {
        body_kk: string
        body_ru: string
        title_kk: string
        title_ru: string
        image_kk: string | File
        image_ru: string | File
    }
}

export interface EventModel {
    id: number
    residents: EventResidentModel[]
    created_at: string
    updated_at: string
    slug: string
    title: string
    body: string
    location: string
    date: string
    image: string
    video: string
    location_link: string
    active: boolean
    user_limit: number
    user_registered: number
    extra: {
        title_ru: string
        title_kk: string
        body_ru: string
        body_kk: string
        location_kk: string
        location_ru: string
    }
}

export interface EventResidentModel {
    id: number
    created_at: string
    updated_at: string
    name: string
    order: number
    image: string
    event: number
}

export interface EventRequestModel {
    id: number
    created_at: string
    updated_at: string
    name: string
}


export type ProductType = "set" | "product" | "service"

export interface ProductOrder {
    id: number
    name: string
    category: number
    type: ProductType
    product: number
    order: number
}

export const ProductTypeName: Record<ProductType, string> = {
    "set": "Комплект",
    "product": "Продукт",
    "service": "Сервис",
}