import {Link, useNavigate} from "react-router-dom"
import HeaderContent from "src/components/header/header-content"
import Icon from "src/components/shared/components/material-icon"
import {SmsListComponent} from "./list"
import {useEffect, useState} from "react"
import {IntegrationModel} from "src/models/integration"
import integrationService from "src/services/integration"

export function SmsIntegrationComponent() {
    const navigate = useNavigate()
    const [integration, setIntegration] = useState<IntegrationModel>()

    useEffect(() => {
        integrationService.get('sms').then(setIntegration)
        return () => setIntegration(undefined)
    }, [])

    useEffect(() => {
        if (integration && !integration.connected) navigate(-1)
    }, [integration])

    return <>
        <HeaderContent>
            <div className="flex gap-2 items-center header-content">
                <Link to="/integrations" className="text-2xl font-semibold link">Интеграции</Link>
                <Icon icon="chevron_right" />
                <div className="text-2xl font-semibold">SMS подписание</div>
            </div>
        </HeaderContent>

        {integration && integration.connected && <SmsListComponent />}
    </>
}