import {InputHTMLAttributes} from 'react'
import {RegisterOptions, useFormContext} from 'react-hook-form'
import {PickerProps} from 'antd/dist/antd'
import {DatePicker} from "../antd-custom"
import moment from "moment"

interface FormControlConfig extends InputHTMLAttributes<HTMLInputElement> {
  id?: any
  rootclassname?: string
  name: string
  params?: RegisterOptions<any, any>
  label?: string
  className?: string
  datetimeConfig: PickerProps<moment.Moment>
}

export const FormControlDatetime: React.FC<FormControlConfig> = config => {
  const {watch, setValue} = useFormContext()

  const className = `${config.className ? config.className : ''} form-control `
  const value = watch(config.name) ? moment(watch(config.name)) : undefined
  const onChange = (date: moment.Moment) => {
    setValue(config.name, date.format('YYYY-MM-DD HH:mm'), {shouldDirty: true, shouldTouch: true})
  }

  return (
    <div className={config.rootclassname || ''}>
      {config.label && (
        <div className="flex gap-2 mb-2">
          <label className="overflow line-clamp-1 text-[13px] color-gray-400">{config.label}</label>
          {config.required && <div className="form-required"></div>}
        </div>
      )}
      <DatePicker
        format="DD-MM-YYYY HH:mm"
        value={value}
        className={className}
        onChange={onChange}
        changeOnBlur={true}
        {...config.datetimeConfig}
      />
    </div>
  )
}
