import {useEffect, useState} from 'react'
import authService, {ProfileModel} from "src/services/auth.service"
import Icon from "src/components/shared/components/material-icon"
import rentalPointService from "../modal/global/rental-point.service"
import {Tooltip} from "antd"
import './aside.scss'

export function CurrentRentalPoint({collapse}: {collapse: boolean}) {
    const [profile, setProfile] = useState<ProfileModel>()

    useEffect(() => {
        const sub = authService.profile$.subscribe(setProfile)
        return () => sub.unsubscribe()
    }, [])
    const content = <div className="menu-profile mb-2">
        <div className="menu-profile-icon">
            <Icon icon="location_on" className="text-base color-white" />
        </div>
        <div className="menu-profile-name">
            {rentalPointService.point ? <>{rentalPointService.point.name}</> : <>Без пункта проката</>}
        </div>
    </div>

    return profile && !profile.is_superuser && (
        collapse ? (
            <Tooltip placement="right" title={rentalPointService.point ? rentalPointService.point.name : "Без пункта проката"} >
                {content}
            </Tooltip>
        ) : content
    )
}