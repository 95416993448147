import {Action, configureStore} from '@reduxjs/toolkit'
import auth from './auth_store'
import loading from './loading_store'
import app from '../app.slice'

const middlewareConfiguration = {serializableCheck: false}

export const store = configureStore({
  reducer: {
    app,
    auth,
    loading,
  },
  devTools: {
    name: 'Yume CRM',
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware(middlewareConfiguration),
})
export type State = ReturnType<typeof store.getState>

export function dispatchOnCall(action: Action) {
  return () => store.dispatch(action)
}
