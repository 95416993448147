import axios from 'axios'
import {BehaviorSubject, from, Observable, shareReplay, switchMap} from "rxjs"
import {ApiAbstract} from 'src/abstract/api.abstract'
import {axiosResponce} from "src/interceptor/axios-response"
import {IntegrationCategoryModel, IntegrationModel} from "src/models/integration"

class IntegrationService extends ApiAbstract {
    constructor() {
        super('v1/crm/integrations')
    }

    listIntegrations$ = new BehaviorSubject<void>(null)
    listIntegrations = () => this.listIntegrations$.next()

    integrations$: Observable<IntegrationModel[]> = this.listIntegrations$.pipe(
        switchMap(() => from(axios.get<IntegrationModel[]>('/v1/crm/integrations/')).pipe(axiosResponce)),
        shareReplay(1)
    )

    public async listCategories(): Promise<IntegrationCategoryModel[]> {
        const response = await axios.get(this.getUrl('categories'))
        return response.data
    }
    public async get(code: string): Promise<IntegrationModel> {
        const response = await axios.get(this.getUrl(code))
        return response.data
    }
    public async connect(code: string): Promise<undefined> {
        const response = await axios.post(this.getUrl(`${code}/connect`), {})
        this.listIntegrations()
        return response.data
    }
}
const integrationService = new IntegrationService()
export default integrationService
