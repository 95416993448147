import FormSelectAsync, {FormSelectAsyncConfig} from "../../inputs/form-select-async.list"
import {firstValueFrom, map, Observable} from "rxjs"
import appService from "src/services/app.service"
import {InventoryStateStatus} from "src/models/manager/constants"
import {useTranslation} from "react-i18next"
import {LabelModel} from "src/models/label"

type RemovalKeys = 'listOptions' | 'getValue' | 'getOptionLabel' | 'getOptionValue' | 'placeholder' | 'label'
const paymentTypes: Observable<LabelModel[]> = appService.getConstant('PAYMENT_TYPES')

export function SharedPaymentTypeSelect(config: Omit<FormSelectAsyncConfig<InventoryStateStatus>, RemovalKeys>) {
    const {t} = useTranslation()
    return <FormSelectAsync<LabelModel>
        {...config}
        label={t('common.select.payment')}
        placeholder={t('common.select.placeholder.payment')}
        listOptions={() => paymentTypes}
        getValue={(id: number) => firstValueFrom(paymentTypes.pipe(map(list => list.find(obj => +obj.id === +id))))}
        getOptionLabel={(option: LabelModel) => (option ? String(option.label) : null)}
        getOptionValue={(option: LabelModel) => (option ? String(option.id) : null)}
    />
}