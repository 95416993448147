import {CategoryModel} from "src/models/manager/constants"
import FormSelectAsync, {FormSelectAsyncConfig} from "../../inputs/form-select-async.list"
import {Observable, firstValueFrom, map} from "rxjs"
import appService from "src/services/app.service"
import {LabelModel} from "src/models/label"
import GenericEntityService from "src/services/generic-entity.service"
import {ConfigAddOrEdit} from "src/pages/config/config-add-or-edit"
import modalService, {ModalConfig} from "src/components/modal/global/modal.service"
import {FormControl} from "../../inputs/form-control"

type RemovalKeys = 'listOptions' | 'getValue' | 'getOptionLabel' | 'getOptionValue'
const attractionTypes: Observable<LabelModel[]> = appService.getConstant('CLIENT_ATTRACTION_METHOD')

interface ClientSelectConfig extends Omit<FormSelectAsyncConfig<LabelModel>, RemovalKeys> {
    addOption?: boolean
}

export function SharedClientAttractionSelect(config: ClientSelectConfig) {
    const service = new GenericEntityService<LabelModel>('v1/crm/attraction_methods')

    const onCreate = async payload => {
        payload.comment = payload.comment || null
        const res = await service.post(payload)
        appService.loadConstants()
        return res
    }

    const modalProps: ModalConfig = {
        props: {size: 'lg', backdrop: true},
        component: (
            <ConfigAddOrEdit<CategoryModel>
                onSubmit={inventory => onCreate(inventory).then(() => modalService.closeModal())}
                buttonTitle="Добавить"
                title="Новый вид привлечения"
                mode="add"
            >
                <FormControl
                    name="name"
                    label="Название"
                    rootclassname="col"
                    className="form-control"
                    params={{required: true}}
                    required
                />
            </ConfigAddOrEdit>
        )
    }

    return <FormSelectAsync<LabelModel>
        {...config}
        modalProps={config.addOption && modalProps}
        listOptions={() => attractionTypes}
        getValue={(id: number) => firstValueFrom(attractionTypes.pipe(map(list => list.find(obj => obj.id === id))))}
        getOptionLabel={(option: LabelModel) => (option ? String(option.label) : null)}
        getOptionValue={(option: LabelModel) => (option ? String(option.id) : null)}
    />
}