import Table from "src/components/shared/antd-custom/table"
import type {ColumnsType} from 'antd/es/table'
import {useEffect, useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import PaginationFormComponent from "src/components/shared/pagination/pagination-form"
import {EMPTY_LIST, ListModel} from 'src/models/common'
import toDateTime from "src/utils/date-time"
import {priceBeautify} from "src/utils/price"
import smsService from "src/services/integration/sms.service"
import {SmsModel} from "src/models/integration/sms"
import {Link} from "react-router-dom"
import Icon from "src/components/shared/components/material-icon"

const messageType = {
  0: 'Простое sms',
  1: 'Документ',
  2: 'Код подтверждения'
}

export function SmsListComponent() {
  const form = useForm({defaultValues: {page: 1, pageSize: 10}})
  const {watch, setValue} = form
  const [balance, setBalance] = useState<number>(0)
  const [list, setList] = useState<ListModel<SmsModel>>(EMPTY_LIST)
  const values = watch()

  const columns: ColumnsType<SmsModel> = [
    {
      title: "№",
      key: 'id',
      dataIndex: 'id',
      render: id => id
    },
    {
      title: "Номер(а)",
      key: "phones",
      dataIndex: 'phones',
      render: phones => phones.join(', ')
    },
    {
      title: "Клиент",
      key: "client",
      render: (_, record) => <Link to={`/clients/${record.client_id}`} className="text-nowrap link">{record.client_name}</Link>
    },

    {
      title: "Тип сообщения",
      key: "type",
      dataIndex: 'type',
      render: type => messageType[type]
    },
    {
      title: "Документ",
      key: "document",
      dataIndex: 'document',
      render: (document, record) => document ? (
        <Link to={`/documents/${record.template}/entity/${document}`} className="link">{record.document_name}</Link>
      ) : (
        <span>Отсутствует</span>
      )
    },
    {
      title: "Аренда",
      key: "request_id",
      dataIndex: 'request_id',
      render: request => request ? (
        <span className="flex gap-2 justify-between items-center">
          <Link to={`/orders/${request}/all`} className="link">Аренда №{request}</Link>
          <Icon icon="chevron_right" />
        </span>
      ) : (
        <span>Отсутствует</span>
      )
    },
    {
      title: "Цена SMS",
      key: 'cost',
      render: (_, record) => `₸ ${priceBeautify(record.cost)}`
    },
    {
      title: 'Дата и время',
      key: 'created_at',
      dataIndex: 'created_at',
      render: created_at => toDateTime(created_at)
    }
  ]

  const listServices = (params: any) => smsService.listMessages(params)
    .then(setList)
    .catch(e => e.response.status === 404 && setValue('page', 1))

  const getBalance = () => smsService.getAccount().then(({balance}) => setBalance(balance || 0))

  useEffect(() => {
    listServices(values)
    getBalance()
    const sub = watch((params) => listServices(params))
    return () => sub.unsubscribe()
  }, [])


  return (
    <FormProvider {...form}>
      <Table
        title={() => (
          <div className="flex gap-2 justify-between items-center">
            <div className="text-xl font-bold">Баланс: ₸ {priceBeautify(+balance)}</div>
            <a
              href="https://wa.me/+7779479990"
              target="_blank"
              className="btn btn-primary btn-color-white py-1"
              rel="noreferrer"
            >
              Пополнить счет
            </a>
          </div>
        )}
        rowKey={obj => obj.id}
        columns={columns}
        dataSource={list.results}
      />
      <PaginationFormComponent count={list.count} />
    </FormProvider>
  )
}
