import {Pagination} from 'antd'
import {useFormContext} from 'react-hook-form'
import {colourStyles} from "../inputs/form-select-color-style"
import CustomSelect from "../inputs/select/select"
import {useMemo} from "react"
import {useTranslation} from "react-i18next"
import clsx from "clsx"

export default function PaginationFormComponent(config: {
  count: number
  component?: JSX.Element
  className?: string
}) {
  const {watch, reset} = useFormContext()
  const {t} = useTranslation()
  const values = watch()
  const page = +watch('page') || 1
  const pageSize = +watch('pageSize') || 10
  const className = config.className || ''
  const options = [
    {value: 10, label: t('common.pagination.10')},
    {value: 20, label: t('common.pagination.20')},
    {value: 50, label: t('common.pagination.50')},
    {value: 100, label: t('common.pagination.100')},
    {value: 500, label: t('common.pagination.500')}
  ]
  const value = useMemo(() => options.find(option => +option.value === +pageSize), [pageSize])

  return (
    <div className={clsx('flex flex-col md:flex-row justify-between items-center gap-2 mt-3', className)}>
      <div className="flex gap-2 items-center self-start">
        {
          config.component || (
            <>
              <CustomSelect
                styles={colourStyles(false)}
                menuPlacement="top"
                isClearable={false}
                isSearchable={false}
                value={value}
                options={options}
                onChange={(val: {value: number, label: string}) => reset({...values, page: 1, pageSize: val.value})}
              />
              <span className="text-nowrap">{t('common.pagination.all', {count: config.count})}</span>
            </>
          )}
      </div>
      <Pagination
        showSizeChanger={false}
        current={Number(page)}
        pageSize={Number(pageSize)}
        onChange={(_page, _pageSize) => {
          reset({
            ...values,
            page: +pageSize !== +_pageSize ? 1 : _page,
            pageSize: _pageSize,
          })
        }}
        total={config.count}
        className="shadow self-end"
      />
    </div>
  )
}
