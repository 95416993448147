import {InputHTMLAttributes} from 'react'
import {RegisterOptions, useFormContext} from 'react-hook-form'
import {getNestedObject} from "src/utils/get-nested-object"

interface FormControlConfig extends InputHTMLAttributes<HTMLInputElement> {
  id?: any
  rootclassname?: string
  name: string
  params?: RegisterOptions<any, any>
  label?: string
  className?: string
}

export const FormControlColor: React.FC<FormControlConfig> = config => {
  const {register, formState: {errors}} = useFormContext()
  const error = getNestedObject(errors, config.name)
  const className = `${config.className ? config.className : ''} form-control-color ${Object.keys(error).length !== 0 ? 'invalid' : ''}`

  return (
    <div className={config.rootclassname || ''}>
      {config.label && (
        <div className="flex gap-2 mb-2">
          <label className="overflow line-clamp-1 text-[13px] color-gray-400">{config.label}</label>
          {config.required && <div className="form-required"></div>}
        </div>
      )}
      {/* <ColorPicker
        value={watch(config.name)}
        onChange={(_, hex) => setValue(config.name, hex)}
      /> */}
      <input
        type="color"
        className={className}
        {...register(config.name, config.params)}
      />
    </div>
  )
}
