import {useTranslation} from "react-i18next"
import {useContext, useEffect, useState} from "react"
import {OrderSaleContext} from "./context"
import {ClientModel} from "src/models/manager/clients/client.model"
import FormSelectAsyncPagination from "src/components/shared/inputs/form-select-async.pagination"
import clientService from "src/services/clients.service"
import {GroupBase, OptionProps} from "react-select"
import ClientEditComponent from "../../clients/edit/clients-edit"
import modalService from "src/components/modal/global/modal.service"
import {useFormContext} from "react-hook-form"
import discountService from "src/services/discount/discount.service"
import {firstValueFrom} from "rxjs"
import {FormControlTextarea} from "src/components/shared/inputs/form-control-textarea"
import Segmented from "src/components/shared/segmented"
import SaleFormGroupComponent from "./form-group"
import {toPrice} from "src/utils/price"
import SaleGroupSummaryComponent from "./summary-group"
import {SaleFormModel} from "src/models/manager/order/order-sale.model"
import Icon from "src/components/shared/components/material-icon"
import {useNavigate} from "react-router"
import {SelectOption} from "src/components/shared/inputs/select/common"
import {SharedPaymentTypeSelect} from "src/components/shared/components/select/payment-type"
import {SharedDiscountTypeSelect} from "src/components/shared/components/select/discount-type"
import "./styles.scss"


function ClientSelectOption(props: OptionProps<ClientModel, boolean, GroupBase<ClientModel>>) {
    return (
        <SelectOption {...props}>
            <div className="flex justify-between gap-2 items-center">
                <span>{props.data.name}</span>
                <span style={{backgroundColor: 'var(--color-primary)', color: 'var(--color-white)', borderRadius: 4, padding: 6}}>{props.data.phone}</span>
            </div>
        </SelectOption>
    )
}

export default function SaleFormComponent() {
    const {t} = useTranslation()
    const {groups} = useContext(OrderSaleContext)
    const {setValue, watch} = useFormContext<SaleFormModel>()
    const [discountType, setDiscountType] = useState<"discount" | "promocode">('discount')
    const navigate = useNavigate()
    const [priceAmount, setPriceAmount] = useState(0)
    const [discountAmount, setDiscountAmount] = useState(0)

    const getPrice = async () => {
        const [_priceAmount, _discountAmount] = await Object.values(watch('_inventories')).reduce(async (prev, curr) => {
            let price = Number(curr.price)

            if (watch('discount')) {
                const discount = await firstValueFrom(discountService.get(Number(watch('discount'))))
                price = (price * (100 - discount.discount)) / 100
            }
            if (watch(`_inventories.${curr.id}.discount`)) {
                const discount = await firstValueFrom(discountService.get(Number(watch(`_inventories.${curr.id}.discount`))))
                price = (price * (100 - discount.discount)) / 100
            }
            return [Number(price) + (await prev)[0], curr.price - Number(price) + (await prev)[1]]
        }, Promise.resolve([0, 0]))

        setPriceAmount(_priceAmount)
        setDiscountAmount(_discountAmount)
    }

    useEffect(() => {
        if (watch()) getPrice()
    }, [watch()])

    return (
        <div className="flex gap-3 flex-col">
            <div className="flex gap-2 items-center mb-3">
                <Icon icon="arrow_back" className="text-xl cursor-pointer" onClick={() => navigate(-1)} />
                <span className="text-2xl font-bold">{t('sale.edit.title.main')}</span>
            </div>

            <span className="color-gray-400">{t('sale.edit.group.title')}</span>

            <div className="flex flex-col gap-3 mb-3">
                {groups.map(group => <SaleFormGroupComponent key={group.id} group={group} />)}
            </div>
            <Segmented
                options={[
                    {label: t('orders.payment.discount'), value: 'discount'},
                    {label: t('orders.payment.promocode_discount'), value: 'promocode'},
                ]}
                onChange={(val) => setDiscountType(val)}
                segmentStyle={{width: '100%'}}
                optionStyle={() => ({padding: '12px 16px'})}
                value={discountType}
            />
            <FormSelectAsyncPagination<ClientModel>
                name="client"
                listOptions={params => clientService.list({...params, skip_loader: true})}
                getValue={(id) => clientService.get(+id)}
                filterOption={null}
                getOptionLabel={(val: ClientModel) => (val ? String(val.name) : null)}
                getOptionValue={(val: ClientModel) => (val ? String(val.id) : null)}
                components={{Option: ClientSelectOption}}
                label={t('common.select.client')}
                placeholder={t('common.select.placeholder.client')}
                modalProps={{
                    props: {size: 'lg', backdrop: true},
                    component: (
                        <ClientEditComponent
                            showHeader={false}
                            onCreate={client => {
                                setValue('client', +client.id, {shouldDirty: true})
                                modalService.closeModal()
                            }}
                        />
                    ),
                }}
                className="flex-1"
            />
            {discountType === 'discount' && (
                <SharedDiscountTypeSelect
                    label={t('common.select.discount')}
                    className="col"
                    name="discount"
                    type="discount"
                    isSearchable={false}
                    isClearable
                />
            )}
            {discountType === 'promocode' && (
                <SharedDiscountTypeSelect
                    label={t('common.select.discount')}
                    className="col"
                    name="discount"
                    type="promocode"
                    isSearchable={false}
                    isClearable
                />
            )}
            <SharedPaymentTypeSelect
                className="col mb-0"
                name="payment_type"
                isClearable={false}
                isSearchable={false}
            />
            <FormControlTextarea
                name="comment"
                className="form-control"
                rootclassname="col"
                rows={5}
                label={t('common.input.comment')}
                placeholder={t('common.input.placeholder.comment')}
            />

            <hr className="my-4 color-gray-200" />

            <div className="sale-summary">

                {groups.map(group => <SaleGroupSummaryComponent key={group.id} group={group} />)}

                {discountAmount !== 0 && (
                    <div className="flex gap-2 justify-between items-end color-gray-400">
                        <span>{t('common.select.discount')}</span>
                        <span className="sale-summary-divider"></span>
                        <span className="font-medium">{Math.round(100 * discountAmount / (discountAmount + priceAmount))}% ({toPrice(discountAmount)})</span>
                    </div>
                )}

                <div className="flex gap-2 justify-between items-end text-base font-medium mt-2">
                    <span>{t('sale.edit.group.total')}</span>
                    <span className="font-bold">{toPrice(priceAmount)}</span>
                </div>
            </div>
        </div>
    )
}
