import {useTranslation} from "react-i18next"
import {FormControl} from "src/components/shared/inputs/form-control"
import {useFormContext} from "react-hook-form"
import {SaleFormModel} from "src/models/manager/order/order-sale.model"
import {useMemo} from "react"
import {SharedDiscountTypeSelect} from "src/components/shared/components/select/discount-type"

export default function SaleFormInventory({inventoryKey}: {inventoryKey: number | string}) {
    const {t} = useTranslation()
    const {watch} = useFormContext<SaleFormModel>()
    const inventory = useMemo(() => watch(`_inventories.${inventoryKey}.inventory`), [inventoryKey, watch])

    return (
        <div className="w-full">
            <div className="color-gray-500 mb-1">{inventory.name} - {inventory.unique_id}</div>
            <div className="flex gap-2 w-full">
                <FormControl
                    placeholder={t('common.input.placeholder.price')}
                    name={`_inventories.${inventoryKey}.price`}
                    className="form-control col"
                />
                <SharedDiscountTypeSelect
                    className="col"
                    name={`_inventories.${inventoryKey}.discount`}
                    isSearchable={false}
                    isClearable
                />
            </div>
        </div>
    )
}
