import {use100vh} from "react-div-100vh"
import './spinner.scss'

export function Spinner() {
  const height = use100vh()
  return (
    <div className="global" style={{height}}>
      <div className="spinner-border text-light"></div>
    </div>
  )
}
