import {useFormContext} from "react-hook-form"
import {ImageField} from "src/components/shared/image-loader/image"
import {InventoryGroupModel} from "src/models/manager/inventory/inventory-group.model"
import {SaleFormModel, SaleInventoryModel} from "src/models/manager/order/order-sale.model"
import {useEffect, useState} from "react"
import {isNil} from "src/utils/isNil"
import Icon from "src/components/shared/components/material-icon"
import settingService from "src/services/settings.service"
import modalService from "src/components/modal/global/modal.service"
import SaleInventoryPriceList from "./form-price"
import {priceBeautify} from "src/utils/price"
import {DiscountModel} from "src/models/manager/discount/discount.model"
import discountService from "src/services/discount/discount.service"
import SaleInventoryDiscountList from "./form-discount"
import SaleFormInventory from "./form-inventory"
import {useTranslation} from "react-i18next"
import "./styles.scss"


export default function SaleFormGroupComponent({group}: {group: InventoryGroupModel}) {
    const {t} = useTranslation()
    const {watch, reset} = useFormContext<SaleFormModel>()
    const [open, setOpen] = useState(false)
    const [currency, setCurrency] = useState('')
    const [discountDict, setDiscountDict] = useState<Record<number, DiscountModel>>({})
    const inventories: SaleInventoryModel[] = Object.values(watch('_inventories')).filter((obj: SaleInventoryModel) => obj.inventory.group === group.id) as SaleInventoryModel[]
    const count = inventories.length
    const discounts = [...new Set(inventories.filter(obj => !isNil(obj.discount)).map(obj => +obj.discount))]
    const prices = [...new Set(inventories.map(obj => obj.price))]
    const onPriceChange = (price: number) => {
        const handleSubmit = (payload: {price: number}) => {
            reset({
                ...watch(),
                _inventories: Object.fromEntries(
                    Object.values(watch('_inventories'))
                        .map(obj => ({...obj, price: obj.price === price && obj.inventory.group === group.id ? payload.price : obj.price}))
                        .map(obj => [obj.id, obj])
                )
            })
            modalService.closeModal()
        }
        modalService.open({
            component: <SaleInventoryPriceList price={price} onChange={handleSubmit} />
        })
    }

    const onDiscountChange = (discount: number) => {
        const handleSubmit = (payload: {discount: number}) => {
            reset({
                ...watch(),
                _inventories: Object.fromEntries(
                    Object.values(watch('_inventories'))
                        .map(obj => ({...obj, discount: obj.discount === discount && obj.inventory.group === group.id ? payload.discount : obj.discount}))
                        .map(obj => [obj.id, obj])
                )
            })
            modalService.closeModal()
        }
        modalService.open({
            component: <SaleInventoryDiscountList discount={discount} onChange={handleSubmit} />
        })
    }

    const onDiscountAdd = () => {
        const handleSubmit = (payload: {discount: number}) => {
            reset({
                ...watch(),
                _inventories: Object.fromEntries(
                    Object.values(watch('_inventories'))
                        .map(obj => ({...obj, discount: obj.inventory.group === group.id ? payload.discount : obj.discount}))
                        .map(obj => [obj.id, obj])
                )
            })
            modalService.closeModal()
        }
        modalService.open({
            component: <SaleInventoryDiscountList onChange={handleSubmit} />
        })
    }

    const onToggle = () => setOpen(p => !p)

    useEffect(() => {
        const sub = settingService.config$.subscribe(config => setCurrency(config.currency))
        return () => sub.unsubscribe()
    }, [])

    useEffect(() => {
        const sub = discountService.discounts$.subscribe(discounts => setDiscountDict(Object.fromEntries(discounts.map(d => [d.id, d]))))
        return () => sub.unsubscribe()
    }, [])

    return (
        <div className="sale-group">
            <div className="flex items-center w-full" style={{gap: 16}}>
                <ImageField className="sale-group-image" src={group.image} />
                <div className="flex flex-col gap-2 flex-1">
                    <span className="text-base font-bold">{group.name}</span>
                    <div className="flex gap-1">
                        <span>{t('sale.edit.group.price')}</span>

                        <div className="flex flex-wrap color-primary font-medium">
                            {prices.map(price => (
                                <span key={price} className="flex items-center gap-1 cursor-pointer">
                                    {priceBeautify(price)} {currency}{t('sale.edit.group.price_entity')}
                                    <Icon icon="edit" className="text-sm" onClick={() => onPriceChange(price)} />
                                </span>
                            )).map((item, index) => [index > 0 && <span>,&nbsp;</span>, item])}
                        </div>
                    </div>
                    <div className="flex gap-1">
                        <span>{t('sale.edit.group.discount')}</span>
                        <div className="flex flex-wrap color-primary font-medium">
                            {discounts.length > 0 ? (
                                discounts.map(discount => (
                                    <span key={discount} className="flex items-center gap-1 cursor-pointer" onClick={() => onDiscountChange(discount)}>
                                        {discountDict[discount]?.name} (-{discountDict[discount]?.discount}%)
                                        <Icon icon="edit" className="text-sm" />
                                    </span>
                                )).map((item, index) => [index > 0 && <span>,&nbsp;</span>, item])
                            ) : (
                                <span className="flex items-center gap-1 cursor-pointer" onClick={onDiscountAdd}>
                                    {t('sale.edit.group.no_discount')}
                                    <Icon icon="edit" className="text-sm" />
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <span className="flex gap-1 text-[13px] items-center">
                    <span dangerouslySetInnerHTML={{__html: t('sale.edit.group.count', {count})}}></span>
                    {count > 1 && <Icon onClick={onToggle} className={`rotated cursor-pointer ${open ? 'rotated-180' : ''}`} icon="expand_more" />}
                </span>
            </div>
            {open && inventories.map(obj => <SaleFormInventory key={obj.id} inventoryKey={obj.id} />)}
        </div>
    )
}
