import isYumeRent from "src/utils/yume-rent"
import {FormControlCheckbox, FormControlConfig} from "../inputs/form-control-checkbox"
import {useTranslation} from "react-i18next"

type RemovalKeys = 'label' | 'placeholder'

export function SharedPublishedCheckbox(config: Omit<FormControlConfig, RemovalKeys>) {
    const {t} = useTranslation()
    return isYumeRent && (
        <FormControlCheckbox
            {...config}
            label={t('common.checkbox.published')}
            className="form-check-input m-0"
            rootclassname="mb-3"
        />
    )
}