import {lazy} from 'react'
import {Navigate, RouteObject} from 'react-router-dom'
import CustomSuspense from "src/components/custom-suspense"
import DeliveryWrapperComponent from "src/pages/deliveries/list"
import DeliveryHistoryComponent from "src/pages/deliveries/list/history"
import DeliveryListComponent from "src/pages/deliveries/list/list"
import {OrderDetailProvider} from 'src/pages/orders/details/context/order-context'
import OrderListTabFilterComponent from "src/pages/orders/list/order-tab.filter"

const DocumentTemplateEntity = lazy(() => import("src/pages/config/documents/entity"))
const DocumentTemplateGeneration = lazy(() => import("src/pages/config/documents/generation"))
const OrderDetailGroupList = lazy(() => import("src/pages/orders/details/inventories/inventory-list/group-list"))
const OrderDetailSetList = lazy(() => import("src/pages/orders/details/inventories/inventory-set-list/list"))
const OrderDetailComponent = lazy(() => import("src/pages/orders/details/order"))
const OrderDetailServiceList = lazy(() => import("src/pages/orders/details/services/services"))
const OrderListComponent = lazy(() => import("src/pages/orders/list/orders"))

const filterRequest = {status: 0, grant: false}
const filterReserve = {status: 1}
const filterInrent = {status: 2}
const filterCompleted = {status: 4, time_exceed: false}
const filterExpired = {time_exceed: true}
const filterDebtor = {status: 5, payment_status__in: '0,2', is_debtor: true}
const filterDeleted = {deleted: true}
const filterCancelled = {status: 3}

export const orderRoutes: RouteObject[] = [
  {
    path: 'orders',
    element: <CustomSuspense><OrderListComponent /></CustomSuspense>,
    children: [
      {path: '', element: <Navigate to="all" relative="route" replace={false} />},
      {path: 'all', element: <OrderListTabFilterComponent filter={{}} />},
      {path: 'requests', element: <OrderListTabFilterComponent filter={filterRequest} />},
      {path: 'reserved', element: <OrderListTabFilterComponent filter={filterReserve} />},
      {path: 'inrent', element: <OrderListTabFilterComponent filter={filterInrent} />},
      {path: 'completed', element: <OrderListTabFilterComponent filter={filterCompleted} />},
      {path: 'expired', element: <OrderListTabFilterComponent filter={filterExpired} />},
      {path: 'debtor', element: <OrderListTabFilterComponent filter={filterDebtor} />},
      {path: 'canceled', element: <OrderListTabFilterComponent filter={filterCancelled} />},
      {path: 'deleted', element: <OrderListTabFilterComponent filter={filterDeleted} />},
    ],
  },
  {
    path: 'orders/:id',
    element: (
      <OrderDetailProvider>
        <CustomSuspense><OrderDetailComponent /></CustomSuspense>
      </OrderDetailProvider>
    ),
    children: [
      {path: '', element: <Navigate to="all" replace={false} />},
      {
        path: 'all',
        element: (
          <>
            <CustomSuspense><OrderDetailGroupList /></CustomSuspense>
            <CustomSuspense><OrderDetailSetList /></CustomSuspense>
            <CustomSuspense><OrderDetailServiceList /></CustomSuspense>
          </>
        ),
      },
      {path: 'inventories', element: <CustomSuspense><OrderDetailGroupList /></CustomSuspense>},
      {path: 'sets', element: <CustomSuspense><OrderDetailSetList /></CustomSuspense>},
      {path: 'services', element: <CustomSuspense><OrderDetailServiceList /></CustomSuspense>},
    ],
  },
  {
    path: 'deliveries',
    element: <DeliveryWrapperComponent />,
    children: [
      {path: '', element: <DeliveryListComponent />},
      {path: 'history', element: <DeliveryHistoryComponent />}
    ],
  },
  {path: 'documents/:id', element: <CustomSuspense><DocumentTemplateGeneration /></CustomSuspense>},
  {path: 'documents/:id/entity/:documentId', element: <CustomSuspense><DocumentTemplateEntity /></CustomSuspense>},
]
