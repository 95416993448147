import Table from "src/components/shared/antd-custom/table"
import {useEffect, useState} from 'react'
import type {ColumnsType} from 'antd/es/table'
import websiteService from "src/services/website.service"
import {EventRequestModel} from "src/models/manager/website"
import toDateTime from "src/utils/date-time"

const EventRequestList = ({id}: {id: number}) => {
    const [list, setList] = useState<EventRequestModel[]>([])

    const columns: ColumnsType<EventRequestModel> = [
        {
            title: 'Название',
            key: 'name',
            dataIndex: 'name',
            render: name => name,
        },
        {
            title: 'Номер',
            key: 'phone',
            dataIndex: 'phone',
            render: phone => phone,
        },
        {
            title: 'Возраст',
            key: 'age',
            dataIndex: 'age',
            render: age => age,
        },
        {
            title: 'Сфера',
            key: 'profession',
            dataIndex: 'profession',
            render: profession => profession.join(', '),
        },
        {
            title: 'Опыт',
            key: 'experience',
            dataIndex: 'experience',
            render: experience => experience,
        },
        {
            title: 'Дата рег.',
            key: 'created_at',
            dataIndex: 'created_at',
            render: created_at => toDateTime(created_at),
        },
    ]

    useEffect(() => {
        websiteService.listEventRequests(id).then(setList)
        return () => setList([])
    }, [id])

    return <div className="mt-3">
        <h3 className="text-[21px] font-semibold mb-3">Регистрации</h3>
        <Table
            rowKey={obj => obj.id}
            columns={columns}
            dataSource={list}
        />
    </div>
}

export default EventRequestList