import Modal, {ModalProps} from 'react-bootstrap/Modal'
import modalService from "./modal.service"
import Icon from "src/components/shared/components/material-icon"

export interface CustomModalConfig extends ModalProps {
  children: JSX.Element
}

export const CustomModal = (config: CustomModalConfig) => {
  const handleModalToggle = () => {
    modalService.closeModal()
    if (config.onHide !== undefined) config.onHide()
  }

  return (
    <Modal centered={true}  {...config} onHide={handleModalToggle}>
      <Modal.Body>
        <div className="modal-remove" onClick={handleModalToggle}>
          <Icon icon="close" />
        </div>
        {config.children}
      </Modal.Body>
    </Modal>
  )
}
