import {useState, useEffect} from 'react'
import modalService, {ModalConfig} from "src/components/modal/global/modal.service"
import {ModalComponent} from "./modal.v2"

export const GlobalModal = () => {
  const [modals, setModals] = useState<Array<[string, boolean, ModalConfig]>>([])

  useEffect(() => {
    const sub = modalService.modals$.subscribe(setModals)
    return () => sub.unsubscribe()
  }, [])

  return <>{modals.map((modal) => <ModalComponent key={modal[0]} index={modal[0]} show={modal[1]} {...modal[2]} />)}</>
}
