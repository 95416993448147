import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import tokenService from 'src/services/token.service'
import authService, {TokenModel} from '../services/auth.service'

interface AuthState {
  token: TokenModel
  isLogged: boolean
}

const initialState: AuthState = {
  token: tokenService.getToken,
  isLogged: tokenService.hasToken,
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setTokens: (state, {payload: token}: PayloadAction<TokenModel>) => {
      state.token = token
      if (!!token.access && !!token.refresh) {
        state.isLogged = true
      } else {
        state.isLogged = false
      }
    },
    onLogout: state => {
      state.isLogged = false
      state.token = undefined
    },
  },
})

export const {setTokens, onLogout} = slice.actions

export default slice.reducer

export const isAuthSelector = state => state.auth.isLogged