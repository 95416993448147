import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ManagerConstantModel} from 'src/models/manager/constants'

interface AppState {
  constants: ManagerConstantModel
}

const initialState: AppState = {
  constants: null,
}

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    startLoadingApp: () => initialState,
    loadConst: (state, {payload: constants}: PayloadAction<ManagerConstantModel>) => {
      state.constants = constants
    },
    clearErrors: state => {
      state = initialState
    },
  },
})

export const {startLoadingApp, loadConst, clearErrors} = slice.actions

export default slice.reducer
