import moment from "moment"
import {useEffect, useState} from "react"
import {timer} from "rxjs"
import tenantService, {TenantModel} from "src/services/tenant.service"
import toDateTime from "src/utils/date-time"
import {ModalComponent} from "../modal/global/modal.v2"
import {useTranslation} from "react-i18next"

const timer$ = timer(0, 1000)

function Timer({tenant, children}: {tenant: TenantModel, children: JSX.Element}) {
    const [days, setDays] = useState<number>()
    const {t} = useTranslation()

    useEffect(() => {
        const timer = timer$.subscribe(() => {
            const duration = moment.duration(moment(tenant.end_at).valueOf() - moment.now(), 'milliseconds')
            setDays(duration.asDays())
        })
        return () => timer.unsubscribe()
    }, [])

    return days && days < 5 ? (
        <>
            <ModalComponent
                component={
                    <div className="flex flex-col gap-4">
                        <div className="flex items-center justify-center bg-red-10" style={{borderRadius: 8, width: 48, height: 48}}>
                            <div className="flex items-center justify-center bg-red color-white text-2xl" style={{borderRadius: "50%", width: 24, height: 24}}>
                                !
                            </div>
                        </div>
                        <div className="text-2xl font-bold">{t('banner.modal.title')}</div>
                        <div className="text-[15px] color-gray-500">{t('banner.modal.message')}</div>
                        <a href="https://wa.me/+7779479990" target="_blank" className="btn btn-bg-light" rel="noreferrer">{t('banner.link')}</a>
                    </div>
                }
                show={days <= 0}
                removable={false}
            />
            {children}
        </>
    ) : (
        <></>
    )
}

export default function HeaderPeriodBanner() {
    const [tenant, setTenant] = useState<TenantModel>()
    const {t} = useTranslation()

    useEffect(() => {
        const sub = tenantService.tenant$.subscribe(setTenant)
        return () => sub.unsubscribe()
    }, [])

    return tenant && (
        <Timer tenant={tenant}>
            <div className="tenant-banner w-full font-medium items-center flex gap-3 px-3 py-2 color-white" style={{backgroundColor: "var(--color-red)"}}>
                <span>{t("banner.message", {datetime: toDateTime(tenant.end_at, "DD MMMM YYYY")})}</span>
                <a href="https://wa.me/+7779479990" target="_blank" className="btn btn-white px-3 py-2" rel="noreferrer">{t('banner.link')}</a>
            </div>
        </Timer>
    )
}